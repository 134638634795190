import React, { Component } from 'react';
import './style/HiringPopUp.scss';
import { Dialog } from "@material-ui/core";
import { CopyToClipboard } from 'react-copy-to-clipboard'

class HiringPopUp extends Component {
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
        index: 1
    }

    handleDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            isOneDialogOpen: false
        })
    }
    
    buttonClick = (index) => {
        if(index === 1){
            this.props.onClickPopupNext();
        } 
        // this.setState({
        //     index,
        // }, () => this.setState({isDialogOpen: true}))
    }
    render() {
        const url = "hiring@loocagency.com"; // url 복사
        const { 
            onClose,
            open,
            noButton,
            dialogType,
            onButtonClick,
            index
        } = this.props;
        return (
            <Dialog fullWidth={this.props.fullWidth} maxWidth={"lg"} open={open} onClose={onClose}>
                <div className='HiringPopUpContainer'>
                    <div className='HiringPopUpMain'>
                        <div className='HiringPopUpTextBox'>
                            <div className='HiringPopUpTitle'>
                                채용 지원
                            </div>
                            <div className='HiringPopUpSub'>
                                준비하신 서류를 이메일로 발송해주시면 채용 지원이 완료됩니다.
                            </div>
                        </div>
                        <CopyToClipboard text={url}>
                            <button onClick={onClose} className='HiringPopUpCheck'>
                                메일 주소 복사
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default HiringPopUp;