//Data Import
import news1 from '../../Data/NoticeData/News1/News1'
import news2 from '../../Data/NoticeData/News2/News2'
import news3 from '../../Data/NoticeData/News3/News3'
import news4 from '../../Data/NoticeData/News4/News4'
import news5 from '../../Data/NoticeData/News5/News5'
import news6 from '../../Data/NoticeData/News6/News6'
import news7 from '../../Data/NoticeData/News7/News7'
import news8 from '../../Data/NoticeData/News8/News8'
import news9 from '../../Data/NoticeData/News9/News9'
import news10 from '../../Data/NoticeData/News10/News10'
import news11 from '../../Data/NoticeData/News11/News11'

//Image Import
import test1 from "../../Data/NoticeData/Image/notice-img-01@2x.png";
import test2 from "../../Data/NoticeData/Image/notice-img-02@2x.png";
import test3 from "../../Data/NoticeData/Image/notice-img-03@2x.png";
import test4 from "../../Data/NoticeData/Image/notice-img-04@2x.png";
import test5 from "../../Data/NoticeData/Image/notice-img-05@2x.png";
import test6 from "../../Data/NoticeData/Image/notice-img-06@2x.png";

export default {
	list: [
		{
			id: "News1",
			img: test1,
			contents: news1,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test1",
			img: test1,
			contents: news2,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Notice"
		},
		{
			id: "test2",
			img: test2,
			contents: news3,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Notice"
		},
		{
			id: "test3",
			img: test3,
			contents: news4,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test4",
			img: test4,
			contents: news5,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test5",
			img: test5,
			contents: news6,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test6",
			img: test6,
			contents: news7,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test7",
			img: test1,
			contents: news8,

			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test8",
			img: test2,
			contents: news9,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test9",
			img: test3,
			contents: news10,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test10",
			img: test4,
			contents: news11,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
	],
};
