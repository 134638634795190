import React, { Component } from "react";
import Lottie from "react-lottie";
import "./Style/ThirdPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import LinkClick from "../../Common/LinkClick/LinkClick";
import Animation from "../../_asset/lottie/third.json";
import backimg from "../../_asset/images/grvina/03-bgimg-01@3x.webp";
import iconCircle from "../../_asset/images/grvina/ic-circle@3x.png";
import arrowLeft from "../../_asset/images/grvina/03-arrow-l@3x.png";
import arrowRight from "../../_asset/images/grvina/03-arrow-r@3x.png";

// console.log(this.props.testPage);
class ThirdPage extends Component {
  render() {
    return (
      <section
        className="ThirdPageGrvina"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <img src={backimg} alt="G.R Vina" className="ThirdPageGrvina__bg" />
        <div className="ThirdPageGrvina__bottom">
          <div className="ThirdPageGrvina__left">
            <span className="ThirdPageGrvina__left-text">
              베트남 생산 공장 전경{" "}
            </span>
            <div className="ThirdPageGrvina__left-content">
              <span className="ThirdPageGrvina__left-text">GR VINA</span>
              <img
                className="ThirdPageGrvina__left-img"
                src={iconCircle}
                alt=""
              />
            </div>
          </div>
          <div className="ThirdPageGrvina__right">
            <img alt="arrowLeft" src={arrowLeft} />
            <img alt="arrowRight" src={arrowRight} />
          </div>
        </div>
      </section>
    );
  }
}

export default ThirdPage;
