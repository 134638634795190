import React, { useState, useEffect } from "react";
import "./style/video.scss";
import styled from "styled-components";
import Videovd from "../../../../_asset/images/29TV/video_br_video.mp4";
import videoimg01 from "../../../../_asset/images/29TV/img_br_video01.jpg";
import videoimg02 from "../../../../_asset/images/29TV/img_br_video02.jpg";

const Title = styled.div`
  font-size: 65px;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  position: relative;
  z-index: 10;
  width: 1226px;
  margin: 0 auto;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Video = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 25000) {
      setTrigger(true);
    }
  }, [pageY]);
  return (
    <div className="videocontainer">
      <div className="video_top">
        <Title trigger={trigger}>
          VIDEO <br />
          SHOPPING
        </Title>
        <div className="video_wrap">
          <video id="video_vd" loop autoPlay muted>
            <source src={Videovd} alt="video_vd01" />
          </video>
          <div className="img_wrap">
            <div className="video_img01">
              <img src={videoimg01} alt="video_img01" />
            </div>
            <div className="video_img02">
              <img src={videoimg02} alt="video_img02" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
