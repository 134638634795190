import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/archive.scss";
import archivevd from "../../../../_asset/images/29TV/archive.mp4";
import archivevd02 from "../../../../_asset/images/29TV/archive02.mp4";
import archiveimg from "../../../../_asset/images/29TV/img_tag.png";
import vlogimg from "../../../../_asset/images/29TV/img_vlog.png";

const Title = styled.div`
  font-size: 55px;
  text-align: right;
  line-height: 1.7;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  text-align: right;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1.2s linear;
  color: #fff;
`;

const VideoWrap = styled.div`
  position: relative;
  margin-top: 198px;
  text-align: center;
  > video {
    width: 334px;
    height: 720px;
    border-radius: 30px;
    border: 2px solid #fff;
  }
`;

const Archivevideo = styled.div`
  position: relative;
  float: left;
  margin: -80px 0 0 45px;

  > video {
    vertical-align: top;
    width: auto;
    height: 720px;
    border-radius: 37px;
    border: solid 6px #161616;
    box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
    margin-bottom: 62px;
  }
`;
const ArchiveList = styled.div`
  display: flex;
  position: relative;
  float: right;
  margin: -80px 0 0 45px;
`;
const Editor = styled.div`
  font-size: 43px;
  letter-spacing: 10px;
  font-weight: 600;
  color: #fff;
`;

const Editorsub = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  color: #fff;
`;

const ArchiveContents = styled.div`
  position: relative;
  float: left;
  margin: -80px 0 0 45px;
  display: flex;
`;

const Middle = styled.div`
  margin: 300px 100px 0 0;
  text-align: right;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.5s linear;
  transition: transform 1.5s linear;
`;

const Bottom = styled.div`
  text-align: right;
  margin-top: 275px;
  margin-left: 300px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const Text = styled.div`
  float: right;
  margin: 228px 0 0 110px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const Archive = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 19000) {
      setTrigger(true);
    }
  });
  return (
    <div className="archivecontainer">
      <div className="cnt_top">
        <div className="archive_top">
          <Title trigger={trigger}>
            USER <br />
            CONTENTS
            <br />
            ARCHIVE
          </Title>
          <Subtitle trigger={trigger}>
            런칭 플랫폼 29CM 2.0은 유저의 콘텐츠가 아카이빙되는 <br />
            29TV를 통해 유저와 브랜드를 연결합니다.
          </Subtitle>
          <VideoWrap>
            <video id="archive_video" loop autoPlay>
              <source src={archivevd} alt="archive_vd" />
            </video>
          </VideoWrap>
        </div>
      </div>
      <div className="cnt_bottom">
        <div className="desc_bx">
          <Archivevideo>
            <video id="archive_video_2" loop autoPlay>
              <source src={archivevd02} alt="archive_vd_02" />
            </video>
            <Text trigger={trigger}>
              <Editor>EDITOR</Editor>
              <Editorsub>
                유저가 직접 찍거나 저장되어 있는 영상을 스스로 편집하여
                <br />
                만들 수 있는 기능입니다. 자막이나 음악을 추가할 수도 있습니다.
              </Editorsub>
            </Text>
          </Archivevideo>
        </div>
      </div>
      <div className="cnt_bottomTwo">
        <div className="desc_bx">
          <ArchiveList>
            <Middle trigger={trigger}>
              <div className="middle_title">
                PRODUCT <br />
                TAG
              </div>
              <div className="middle_sutext">
                유저는 본인이 제작한 크리에이티브 영상과 관련되거나 <br />
                어울릴법한 상품을 태그할 수 있으며 타 커머스에서 판매중인 상품도
                태그할 수 있습니다.
              </div>
            </Middle>
            <div className="archive_Wrap">
              <img src={archiveimg} alt="archiveimg" />
            </div>
          </ArchiveList>
        </div>
      </div>
      <div className="cnt_bottomThree">
        <div className="desc_bx">
          <ArchiveContents>
            <div className="ImgageWrap">
              <img src={vlogimg} alt="archive_Image" />
            </div>
            <Bottom trigger={trigger}>
              <div className="bottom_title">
                SHORT-TERM <br />
                VLOG
              </div>
              <div className="bottom_subtext">
                유저는 그들의 앨범에 있는 사진이나 동영상을 1초 단위로 <br />
                짜깁기하여 쉽게 라이프스타일을 공유할 수 있습니다.
              </div>
            </Bottom>
          </ArchiveContents>
        </div>
      </div>
    </div>
  );
};

export default Archive;
