import React, { useEffect, useState } from "react";
import "./CustomSelect.scss";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import DownArrow from "../../_asset/images/mainback/gray-downdrop-icon.svg";

const CustomSelect = ({
  id,
  options,
  handleChange,
  name,
  defaultValue,
  placeholder,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [text, setText] = useState(placeholder);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (value) {
      const option = options.find((option) => option.value === value);
      setText(option.text);
      handleChange({ target: { name, value } });
    }
  }, [value]);
  const handleClick = (value) => {
    // handleChange({ target: { name, value: option.value } });
    setExpanded(false);
    setValue(value);
  };
  const renderOptions = (options) =>
    options.map(({ value, text }) => (
      <AccordionDetails
        onClick={() => handleClick(value, text)}
        className="customSelect__item"
      >
        <div>{text}</div>
      </AccordionDetails>
    ));
  const onChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="customSelect">
      <Accordion
        className="customSelect__wrapper"
        expanded={expanded === "panel1"}
        onChange={onChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="customSelect__content"
        >
          <Typography className="select-placeholder"> {text}</Typography>
          <div className="CheckArrow">
            <img className="DownArrow" src={DownArrow} alt="DownArrow" />
          </div>
        </AccordionSummary>
        {renderOptions(options)}
      </Accordion>
    </div>
  );
};

CustomSelect.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
};

export default CustomSelect;
