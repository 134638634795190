import React, { Component } from 'react';
import './FooterNav.scss';

class FooterNav extends Component {
    render() {
        return (
            <div className='FooterNavContainer'>
                {/* <div className ='sessions'>
                    <span>
                        Today&nbsp;
                        3,198
                    </span>
                    <span>
                        Total&nbsp;
                        1,997,146
                    </span>
                </div> */}
                <div className='linksBox'>
                    <ul className='links'>
                        <li className='links_item'>
                            <a href='https://www.behance.net' target='_blank' className='hv-underline'>
                                Behance
                        </a>
                        </li>
                        <li className='links_item'>
                            <a href='https://www.facebook.com' target='_blank' className='hv-underline'>
                                Facebook
                        </a>
                        </li>
                        <li className='links_item'>
                            <a href='https://www.instagram.com' target='_blank' className='hv-underline'>
                                Instagram
                        </a>
                        </li>
                        <li className='links_item'>
                            <a href='https://www.vimeo.com' target='_blank' className='hv-underline'>
                                Vimeo
                        </a>
                        </li>
                    </ul>
                </div>
                <div className='copyright'>
                    ⓒ LOOC AGENCY. All rights reserved.
                </div>
            </div>
        );
    }
}

export default FooterNav;