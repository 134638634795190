import React from "react";
import "./style/Kcover.scss";
import covertxt01 from "../../../../_asset/images/Kaola/txt_cover1.png";
import covertxt02 from "../../../../_asset/images/Kaola/txt_cover2.png";

const Kcover = () => {
  return (
    <div className="Kcover_container">
      <div className="Kcover_cont">
        <div className="Kcover_cont_bx">
          <div className="Kcover_txt1">
            <div className="Kcover-img">
              <img src={covertxt01} alt="cover_image01" />
            </div>
          </div>
          <div className="Kcover_txt2">
            <div className="Kcover-img2">
              <img src={covertxt02} alt="cover_image02" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kcover;
