import React from "react";
import "./style/GprojectC.scss";
import styled from "styled-components";
// import proposeicon from "../../../../_asset/images/g9/img_section02_7.png";
// import proposecimg from "../../../../_asset/images/g9/img_propose_c_device.png";

const GprojectC = () => {
  return (
    <div className="Project_C_container">
      <div className="inner"></div>
    </div>
  );
};

export default GprojectC;
