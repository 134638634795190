import React, { useState, useEffect } from "react";
import "./style/Goverview.scss";
import styled from "styled-components";
import txtimg from "../../../../_asset/images/g9/img_txt_section01_2.png";

const Title = styled.div`
  position: relative;

  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Visual = styled.div`
  position: relative;
  height: 781px;
  margin-top: 290px;
  padding-left: 180px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
`;

const Justice = styled.div`
  overflow: hidden;
  position: relative;
  height: 538px;
  margin-top: 46px;
  padding-bottom: 150px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.8s linear;
  transition: transform 1.8s linear;
`;

const Project = styled.div``;

const Goverview = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 300) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gstrategycontainer">
      <div className="container">
        <Title trigger={trigger}>
          <div className="desc_top">overview</div>
          <div className="desc_tit">
            CREATE AN IMPROVED <br></br>
            E-COMMERCE EXPERIENCE
          </div>
          <div className="desc_txt">
            ‘쇼핑에 상식을 채우다는 슬로건으로 가파르게 성장중인 온라인 커머스
            G9는 큐레이션’ 이라는 차별화 포인트로 트렌드, 품질, 가격 등을{" "}
            <br></br>
            고려해 가장 경쟁력 있는 제품과 딜을 소개하여 소비자들이 더 나은
            선택을 할수 있도록 도와주고 있습니다. 우리는 이러한 G9의 상품뿐만{" "}
            <br></br>
            아니라 콘텐츠 큐레이션을 통해 더 나은 쇼핑경험과 가치를 제공하여
            한발짝 앞선 ‘큐레이션 커머스’를 목표로 하였고, 새로운 모바일 App{" "}
            <br></br>
            개편을 위해 서비스 방향 및 전략, 사용자 경험, 디자인, 콘텐츠 등을
            함께 고민하였습니다.
          </div>
        </Title>
        <Visual trigger={trigger}>
          <div className="bg_area">
            <span></span>
          </div>
          <div className="txt_top_area">
            <div className="txt_box_inner">
              <div className="tix_Wrap">
                <img src={txtimg} alt="Gcover_txt" />
              </div>
              <div className="desc_txt">
                <div className="txt_area">
                  <span>G9는</span>
                  <span>아이덴티티가 분명해야 한다.</span>
                  <span>가까운 친구같고 친근해야 한다.</span>
                  <span>사용자의 액션에 따라 반응하여야 한다.</span>
                  <span>다양한 상품을 끊임없이 둘러볼 수 있어야 한다.</span>
                  <span>브랜드 상품을 판매하는 믿음직한 플랫폼이다.</span>
                  <span>콘텐츠 중심의 비쥬얼 커머스이다.</span>
                </div>
              </div>
            </div>
          </div>
        </Visual>
        <Justice trigger={trigger}>
          <div className="justice_area">
            <div className="justice_top">G9에 대해 6가지 정의를 세우다.</div>
            <div className="justice_bottom">
              프로젝트를 진행하면서 국내의 다양한 쇼핑커머스를 리서치하였는데,
              대부분의 커머스가 유사한 <br></br>
              UX/UI 쇼핑 경험을 제공하고 있었습니다. ebay코리아의 담당자분들은
              이런한 점을 벗어나기 위해, <br></br>
              쇼핑커머스를 만들고, 운영했던 G9의 내부의 시선이 아닌 PlusX만의
              시선으로 쇼핑커머스를 바라보고 <br></br>
              새로운 쇼핑커머스 경험을 만들주길 요청하였습니다. 그래서 우리는
              우리의 시각으로 G9에 대해 6가지 <br></br>
              정의를 내리고, 이러한 점을 반영하여, G9를 자주 방문하고, 볼거리가
              풍부하고, 원하는 상품을 빠르게 <br></br>
              찾을수 있게 하고자 하였습니다.
            </div>
            <span className="side_box"></span>
          </div>
        </Justice>
        <Project>
          <div className="Top_txt_bx">
            <div className="Top_tit">Project Goal</div>
            <div className="Top_title">
              PROVIDE A BETTER<br></br>
              EXPERIENCE<br></br>
              IN G9
            </div>
          </div>
        </Project>
      </div>
    </div>
  );
};

export default Goverview;
