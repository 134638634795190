import React, { Component } from "react";
import "./Style/SecondPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import video from "../../_asset/lottie/second.mp4";
import bgvideo from "../../_asset/lottie/second_bg.mp4";
// import footerAnimationMobile from '../../_asset/animation/landing_07_mobile.json';
import Media from "react-media";
import LinkClick from "../../Common/LinkClick/LinkClick";
import backimg from "../../_asset/images/mainback/bg-02@3x.png";
import TypeIt from "typeit-react";

// api fullpage.js 문서 참고 : https://github.com/alvarotrigo/fullPage.js#lazy-loading
// data-autoplay =  Lazy loading 형태로 Section에 진입해야지만 재싱되도록 설정
// data-keepplaying = section이동시 자동으로 멈추는 Default 값을 끄는 옵션 (지속적으로 Background 에서 재생)
// data-src = 소스 파일을 Lazy loading 처리하는 옵션

class SecondPage extends Component {
  render() {
    return (
      <div className="SecondPage">
        <section style={{}}></section>
        <div className="video-wrap">
          {/* <div className="BackImg">
						<img src={backimg} alt='Background-image' />
					</div> */}
          {/* <video className='bg-background-video'>
						<source data-src ={bgvideo} type='video/mp4' />
					</video> */}
          {/* <div>
						<video className='animation-video' loop data-autoplay>
							<source data-src ={video} type='video/mp4' />
						</video>
					</div> */}
        </div>
        {/* <div className="Link">
					<div className='LinkClickContainer'>
						<div className='LinkClickText'>
							<a href='/about'>Click</a>
						</div>
					</div>
				</div> */}

        <div className="SecondPage__text">
          <div className="content">
            <div className="title">
              <TypeIt
                options={{
                  loop: true,
                  speed: 200,
                  waitUntilVisible: true,
                  loopDelay: 100,
                }}
                getBeforeInit={(instance) => {
                  instance
                    .type("ONLY THOSE WHO")
                    .pause(750)
                    .delete(2)
                    .pause(500)
                    .type("MOVE SURVIVE");

                  return instance;
                }}
              />
            </div>
            <div className="number">01</div>
            <div className="subtitle">The future of Nomad</div>
          </div>
        </div>

        <div className="SecondPage__designText">
          <div className="content">
            <div className="title">
              <TypeIt
                options={{
                  loop: true,
                  speed: 500,
                  loopDelay: 200,
                }}
                getBeforeInit={(instance) => {
                  instance.type("DONT STOP MOVING");
                  return instance;
                }}
              />
            </div>
          </div>
        </div>
        <div className="SecondPage__thirdText">
          <div className="content">
            <div className="title">
              <TypeIt
                options={{
                  loop: true,
                  speed: 200,
                  loopDelay: 200,
                }}
                getBeforeInit={(instance) => {
                  instance.type("DONT STOP MOVING CREATIVELY");
                  return instance;
                }}
              />
            </div>
            <div className="number">02</div>
            <div className="subtitle">Beyound Development to a New Future</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SecondPage;
