import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/social.scss";
import img01 from "../../../../_asset/images/29TV/img_social01.jpg";
import img02 from "../../../../_asset/images/29TV/img_social02.jpg";
import img03 from "../../../../_asset/images/29TV/img_social03.jpg";
import img04 from "../../../../_asset/images/29TV/img_social04.jpg";

const Title = styled.div`
  font-size: 55px;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1.2s linear;
  color: #fff;
`;

const ImageList = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
  margin: 283px -223px 280px 0;
  display: flex;
`;

const Social = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 17000) {
      setTrigger(true);
    }
  });
  return (
    <div className="socialcontainer">
      <div className="social_top">
        <Title trigger={trigger}>
          SOCIAL <br />
          SHOPPING
        </Title>
        <Subtitle trigger={trigger}>
          고객은 29TV 콘텐츠를 소비하는 과정에서 매력적이거나 니즈를 자극하는
          상품이 보이면 <br />
          자연스레 해당 상품에 대한 정보를 얻고 구매를 할 수 있습니다. 나아가
          콘텐츠를 바탕으로, 라이프스타일 취향이 맞는
          <br />
          크리에이터를 팔로우하여 본인의 라이프스타일을 확장할 수 있습니다.
        </Subtitle>
        <ImageList trigger={trigger}>
          <div className="list">
            <img src={img01} alt="socialimage" />
          </div>
          <div className="list">
            <img src={img02} alt="socialimage" />
          </div>
          <div className="list">
            <img src={img03} alt="socialimage" />
          </div>
          <div className="list">
            <img src={img04} alt="socialimage" />
          </div>
        </ImageList>
      </div>
    </div>
  );
};

export default Social;
