import React from "react";
import "./style/Pphone.scss";

const Pphone = () => {
  return (
    <div className="Pphone_container">
      <div className="Pphone_cont_1"></div>
      <div className="Pphone_cont_2"></div>
      <div className="Pphone_tit container">
        <div className="tit">Mobile Website</div>
      </div>
    </div>
  );
};

export default Pphone;
