import React from "react";
import "./style/Ptypography.scss";
import image01 from "../../../../_asset/images/Prorenata/img_prn18.png";
import image02 from "../../../../_asset/images/Prorenata/txt_prn13.png";

const Ptypography = () => {
  return (
    <div className="Ptypography_container">
      <div className="Ptypo_cont_1">
        <div className="container">
          <div className="Ptypo_txt_bx">
            <div className="Ptypo_tit">Typography</div>
            <div className="Ptypo_txt">
              프로레나타의 브랜드 지정서체는 핸드라이팅 스타일의 서체와<br></br>
              셰리프 스타일의 서체를 활용하여 브랜드의 처방전 컨셉을 보다
              직관적으로 전달합니다.
            </div>
          </div>
        </div>
      </div>
      <div className="Ptypo_cont_2">
        <div className="container">
          <div className="Ptypo_image_Wrap">
            <img src={image01} alt="Ptypo_image" />
          </div>
        </div>
      </div>
      <div className="Ptypo_cont_3">
        <div className="Ptypo_image_Wrap_2">
          <img src={image02} alt="Ptypo_image" />
        </div>
      </div>
    </div>
  );
};

export default Ptypography;
