import React from "react";
import "./style/PbgPageFive.scss";
import img01 from "../../../../_asset/images/Prorenata/img_prn27.png";
import img02 from "../../../../_asset/images/Prorenata/img_prn28.png";
import tit01 from "../../../../_asset/images/Prorenata/txt_prn17.png";
const PbgPageFive = () => {
  return (
    <div className="PbgPageFive_container">
      <div className="Five_con_1"></div>
      <div className="Five_con_2">
        <div className="box_Wrap02">
          <img src={img01} alt="image01" />
        </div>
      </div>
      <div className="Five_con_3">
        <div className="box_Wrap03">
          <img src={img02} alt="image02" />
        </div>
      </div>
      <div className="Five_con_4">
        <div className="box_Wrap04"></div>
      </div>
      <div className="Five_con_5">
        <div className="box_Wrap05"></div>
      </div>
      <div className="Five_con_6">
        <div className="box_Wrap06"></div>
      </div>
      <div className="Five_con_7">
        <div className="box_Wrap07"></div>
      </div>
      <div className="Five_con_8">
        <div className="box_Wrap08"></div>
      </div>
      <div className="PbgFive_tit container">
        <img src={tit01} alt="PbaPage_tit" />
      </div>
    </div>
  );
};

export default PbgPageFive;
