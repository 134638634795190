import React, { Component } from 'react'
import './Style/NinePage.scss'
import '../../Common/LinkClick/LinkClick.scss'
import img from '../../_asset/images/grvina/09-bgimg@3x.webp'

const dataSilde = [
  {
    id: 0,
    title: '한/베 FTA에 의한 무역 관세 면제',
  },
  {
    id: 1,
    title: '다년간 축적된 공장 관리와 생산 품질의 우수성',
  },
  {
    id: 2,
    title: '새로운 업무 형태에 대한 적극성과 유연한 조직 구성',
  },
  {
    id: 3,
    title: '고객의 편의를 위해 적극적 know-how를 제공하여 Win-Win 공식을 성립',
  },
  {
    id: 4,
    title:
      '초기 베트남 투자 사업자로서의 안정성과 베트남 숙련된 인력들 이 제공하는 고품질 양말',
  },
]
const dataSildeMobile = [
  {
    id: 0,
    title1: '한/베 FTA에 의한 무역 관세 면제',
    title2: '',
  },
  {
    id: 1,
    title1: '다년간 축적된 공장 관리와 생산 품질의 우수성',
    title2: '',
  },
  {
    id: 2,
    title1: '새로운 업무 형태에 대한 적극성과 유연한 조직 구성',
    title2: '',
  },
  {
    id: 3,
    title1: '고객의 편의를 위해 적극적 know-how를 제공하여',
    title2: 'Win-Win 공식을 성립',
  },
  {
    id: 4,
    title1: '초기 베트남 투자 사업자로서의 안정성과',
    title2: '베트남 숙련된 인력들 이 제공하는 고품질 양말',
  },
]
class NinePage extends Component {
  state = {
    isMobile: window.innerWidth <= 768,
  }
  handleResize = () => {
    // Update isMobile state based on the current window width
    this.setState({ isMobile: window.innerWidth <= 768 }) // You can adjust the threshold as needed
  }

  componentDidMount() {
    // Initial check on component mount
    this.handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener('resize', this.handleResize)
  }
  handleClickBoard = () => {
    navigator.clipboard.writeText('inform.no.mad@gmail.com')
    alert('이메일이 복사되었습니다.')
  }
  render() {
    const { isMobile } = this.state
    return (
      <div
        className="NinePageGrvina"
        style={{ width: '100%', height: '100%', color: '#FFF' }}
      >
        <img alt="" src={img} className="NinePageGrvina__img" />
        <div className="NinePageGrvina__content">
          <div className="NinePageGrvina__heading">GR VINA의 차별성</div>
          {isMobile ? (
            <>
              {dataSildeMobile.map((item, key) => (
                <>
                  <div
                    className="NinePageGrvina__item"
                    key={item.id}
                    onClick={() => this.handleItemClick(item.id)}
                  >
                    <div className="NinePageGrvina__title">
                      <div>{item.title1}</div>
                      <div>{item.title2}</div>
                    </div>
                  </div>
                  <div className="NinePageGrvina__line"></div>
                </>
              ))}
            </>
          ) : (
            <>
              {dataSilde.map((item, key) => (
                <>
                  <div
                    className="NinePageGrvina__item"
                    key={item.id}
                    onClick={() => this.handleItemClick(item.id)}
                  >
                    <div className="NinePageGrvina__title">{item.title}</div>
                  </div>
                  <div className="NinePageGrvina__line"></div>
                </>
              ))}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default NinePage
