import React from "react";
import "./style/Pconcept.scss";
import concept from "../../../../_asset/images/Prorenata/img_prn12.png";

const Pconcept = () => {
  return (
    <div className="Pconcept_container">
      <div className="Pconcept_cont">
        <div className="Pconcept_txt_bx">
          <div className="Pconcept_tit">Brand Design Concept</div>
          <div className="Pconcept_txt">
            브랜드 네임'프로레타나'는 의사나<br></br>
            약사가 처방전에 명기하는 전문 용어로,<br></br>
            필요할 때 수시로 복용하라는 처방의<br></br>
            의미를 지니고 있습니다.<br></br>
            실제 의사가 처방하는 듯한 전문성과<br></br>
            베품력을 지닌 바이오슈티컬 스킨 솔루션<br></br>
            브랜드의 아이덴티티를 강력하게 구축하기<br></br>
            위해 용기 라벨, 타이포그래피 등의<br></br>
            브랜드 디자인 전반에서 '처방전'을<br></br>
            연상할 수 있도록 표현합니다.<br></br>
          </div>
        </div>
        <div className="Pconcept_image">
          <img src={concept} alt="Pconcept_image" />
        </div>
      </div>
    </div>
  );
};

export default Pconcept;
