import React, { Component } from "react";
import Kaola from "../../Experience/Contents/Kaola/Kaola";

class G9Router extends Component {
  render() {
    return (
      <div>
        <Kaola></Kaola>
      </div>
    );
  }
}

export default G9Router;
