import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/launch.scss";
import launchvd from "../../../../_asset/images/29TV/Plus X Creative Partner_3.mp4";
import tunmimg from "../../../../_asset/images/29TV/img_29tv01.jpg";
import thunmbvd from "../../../../_asset/images/29TV/Plus X Creative Partner_4.mp4";
import thunmbimg from "../../../../_asset/images/29TV/img_29tv03.jpg";
import thunmimg from "../../../../_asset/images/29TV/img_29tv05.jpg";
import img01 from "../../../../_asset/images/29TV/img_29tv04.jpg";
import img02 from "../../../../_asset/images/29TV/img_29tv02.jpg";

const Title = styled.div`
  font-size: 55px;
  line-height: 1.7;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  color: #fff;
`;

const ThunmbImage = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  > img {
    width: 320px;
    height: 1484px;
    box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.16);
  }
`;
const ThunmbImagetwo = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  position: absolute;
  top: 390px;
  right: 20px;
  > video {
    position: relative;
    z-index: 10;
    width: 315px;
    height: auto;
    border: none;
  }
`;

const ThunmbImagethree = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  position: absolute;
  position: absolute;
  top: -50px;
  left: -20px;
  > img {
    width: 225px;
    height: 314px;
    box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.16);
  }
`;

const ThunmbImagefour = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  position: absolute;
  position: absolute;
  top: 1365px;
  right: auto;
  left: -30px;
  margin-right: 0;
  > img {
    width: 226px;
    height: 316px;
    box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.16);
  }
`;

const TunmbImageFive = styled.div`
  position: absolute;
  top: 930px;
  left: 50%;
  margin-left: 735px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
  position: absolute;
  position: absolute;
  > img {
    width: 225px;
    height: 314px;
    box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.16);
  }
`;

const Launch = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 14000) {
      setTrigger(true);
    }
  }, [pageY]);

  return (
    <div className="launchcontainer">
      <div className="launch_top">
        <Title trigger={trigger}>
          LAUNCH IN <br />
          29TV
        </Title>
        <Subtitle trigger={trigger}>
          29TV는 Launching Platform입니다.
          <br />
          이 공간에서 유저들은 브랜드에 대한 그들의 생각을 자유롭게 표출할 수
          있으며 브랜드는
          <br />
          새로운 상품을 선보이거나 기존 상품을 새롭게 선보일 수 있습니다.
        </Subtitle>
        <div className="launch_Wrap">
          <video className="launch_vd" id="launch_video" loop autoPlay>
            <source src={launchvd} alt="launch_video" />
          </video>
        </div>
        <div className="thunmb">
          <ThunmbImage trigger={trigger}>
            <img src={tunmimg} alt="thumimage" />
          </ThunmbImage>
          <ThunmbImagetwo trigger={trigger}>
            <video id="thunmb_video" loop autoPlay>
              <source src={thunmbvd} alt="thunmbvd" />
            </video>
          </ThunmbImagetwo>
          <ThunmbImagethree trigger={trigger}>
            <img src={thunmbimg} alt="ThunmbImage" />
          </ThunmbImagethree>
          <ThunmbImagefour trigger={trigger}>
            <img src={thunmimg} alt="ThunmbImage05" />
          </ThunmbImagefour>
          <TunmbImageFive trigger={trigger}>
            <img src={img01} alt="ThunmbImage06" />
          </TunmbImageFive>
        </div>
      </div>
    </div>
  );
};

export default Launch;
