import React, { useState, useEffect } from "react";
import "./style/Equick.scss";
import styled from "styled-components";
import quicktit from "../../../../_asset/images/emart/tit_quick.png";
import quick_list_img01 from "../../../../_asset/images/emart/img_quick1.png";
import quick_list_img02 from "../../../../_asset/images/emart/img_quick2.png";
import quick_list_img03 from "../../../../_asset/images/emart/img_quick3.png";

const Quicktitle = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const UlScreen = styled.div`
  margin-top: 150px;
  font-size: 0;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
`;

const Equick = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 16000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Equickcontainer">
      <div className="con_bx">
        <Quicktitle trigger={trigger}>
          <img src={quicktit} alt="quick_title" />
          <div className="subtitle">
            위젯에서도 고객이 습관적으로 빠르고 간편하게 장볼 리스트를 <br></br>
            메모할 수 있습니다. 마이페이지인지, 포인트 메뉴인지 인지가 불분명
            했던
            <br></br>
            ‘포인트카드’는 ‘마이페이지’로 역할을 집중 시키고 개선하였습니다.
          </div>
        </Quicktitle>
        <UlScreen trigger={trigger}>
          <div className="UlScreen-content row">
            <div className="quick_list01 col col-4">
              <img src={quick_list_img01} alt="quck_image01" />
            </div>
            <div className="quick_list02 col col-4">
              <img src={quick_list_img02} alt="quck_image02" />
            </div>
            <div className="quick_list03 col col-4">
              <img src={quick_list_img03} alt="quck_image03" />
            </div>
          </div>
        </UlScreen>
      </div>
    </div>
  );
};

export default Equick;
