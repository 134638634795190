import React, { Component } from "react";
import FooterMain from "../../Common/FooterMain/FooterMain";
import HeaderMain from "../../Common/HeaderMain/HeaderMain";
import Hiring from "../../Home/Hiring/Hiring";

class RouterHiring extends Component {
  render() {
    return (
      <div>
        <HeaderMain />
        <Hiring />
        <FooterMain />
      </div>
    );
  }
}

export default RouterHiring;
