import React from "react";
import "./style/Pgift.scss";
import gift01 from "../../../../_asset/images/Prorenata/img_prn51.png";

const Pgift = () => {
  return (
    <div className="Pgift_container">
      <div className="Pgift_cont_1">
        <img src={gift01} alt="gift_image01" />
      </div>
      <div className="Pgift_cont_2">
        <div className="Pgift_bx_1"></div>
      </div>
      <div className="Pgift_cont_3">
        <div className="Pgift_bx_2"></div>
      </div>
      <div className="Pgift_tit container">
        <div className="tit">Invitation / Gift Box</div>
      </div>
    </div>
  );
};

export default Pgift;
