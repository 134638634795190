import React, { Component } from 'react';
import {withRouter} from "react-router-dom"
import './Hiring.scss'
import HiringPopUp from '../../Common/PopUp/HiringPopUp'
import Detail from '../../Common/HiringDetail/HiringDetail'

class Hiring extends Component {
    list = [
        {title : "Design", sub : "UIUX / Branding 디자이너", career : "신입/경력", period : "수시채용",personnel: "00명"},
        {title : "Motion", sub : "모션 디자이너 [광고/애니메이션]", career : "경력", period : "수시채용",personnel: "1명"},
        {title : "Motion", sub : "모션 디자이너 [광고/애니메이션]", career : "신입", period : "수시채용",personnel: "1명"},
        {title : "Development", sub : "개발자", career : "경력", period : "수시채용",personnel: "1명"},
        {title : "Development", sub : "개발자", career : "신입", period : "수시채용",personnel: "1명"},
        {title : "Management", sub : "시설 관리 및 매니져", career : "신입", period : "수시채용",personnel: "00명"},
    ]
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
        index: 1
    }
    handleDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            isOneDialogOpen: false
        })
    }
    buttonClick =  (index) => {
        this.setState({
            index,
        }, () => this.setState({isDialogOpen: true}))
    } 

    handleClickLink = (item) => {
        this.props.history.push(`/detail?id=${item.title}`)
    }

    render() {
        return (
            <div className='HiringContainer'>
                <div className='HiringMain'>
                    <div className='HiringTitle'>
                        Hiring
                    </div>
                    <div className='HiringBox'>
                        <div className='HiringTitleLine'>
                            <div className='HiringTitleLineLeft'>
                                <div className='HiringTitleText Job'>
                                    채용직군
                                </div>
                                <div className='HiringTitleText Field'>
                                    분야
                                </div>
                            </div>
                            <div className='HiringTitleLineRight'>
                                <div className='HiringTitleText Career'>
                                신입/경력
                                </div>
                                <div className='HiringTitleText Period'>
                                    채용기간
                                </div>
                                <div className='HiringTitleText Personnel'>
                                채용인원
                                </div>
                            </div>
                        </div>
                        {this.list.map((item, index) => {
                            return (
                                    <div className='HiringSubLineBox'key={index} onClick={() => this.handleClickLink(item)}>
                                        <div className='HiringSubLineLeft'>
                                            <div className='HiringSub Job'>
                                                <a><span>{item.title}</span></a>
                                            </div>
                                            <div className='HiringSub Field'>
                                                <a>{item.sub}</a>
                                            </div>
                                        </div>
                                        <div className='HiringSubLineRight'>
                                            <div className='HiringSub Career'>
                                                <a>{item.career}</a>
                                            </div>
                                            <div className='HiringSub Period'>
                                                {item.period}
                                            </div>
                                            <div className='HiringSub Personnel'>
                                                <a>{item.personnel}</a>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })}
                    </div>
                    <button onClick={() => this.buttonClick()} className='Hiringbutton'>
                        채용 지원하기
                    </button>
                </div>
                <HiringPopUp
                    open={this.state.isDialogOpen}
                    onClickPopupNext ={this.handleClickPopupNext}
                    onClose={this.handleDialogClose}
                    index={this.state.index}
                />
            </div>
        );
    }
}

export default withRouter(Hiring);