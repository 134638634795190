import React, { Component } from 'react'
import '../../Common/LinkClick/LinkClick.scss'
import './Style/SevenPage.scss'
import img from '../../_asset/images/grvina/07-img@3x.webp'

class SevenPage extends Component {
  render() {
    return (
      <div className="SevenPageGrvina">
        <img alt="" src={img} className="SevenPageGrvina__img" />
      </div>
    )
  }
}

export default SevenPage
