import React from 'react';
import './CheckboxButton.scss';
import PropTypes from 'prop-types';

const CheckboxButton = ({ label, id, name, handleChange, requiredText }) => {
    return <div className="checkboxButton">
        <div className="checkboxButton__wrapper">
            <input type="checkbox" id={id} name={name} onChange={() => handleChange && handleChange(id)} />
            <div className="check" />
            <label htmlFor={id}>{label}
                {requiredText && <div className="requiredText">
                    {requiredText}
                </div>}
            </label>

        </div>
    </div>;
}
CheckboxButton.propTypes = {
    label: PropTypes.string.isRequired,
    requiredText: PropTypes.string,
    handleChange: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.any.isRequired,
}
export default CheckboxButton;