import React, { useState, useEffect, useMemo } from "react";
import "./Grid.scss";
import MenuTab from "../../../Home/WorksTab/MenuTab";
import DisplayData from "../../../Common/Id/WorksId/index";
import MUGrid from "@material-ui/core/Grid";
import Square from "./Square/Square";
import FullScreenSquare from "./FullScreenSquare/FullScreenSquare";
import { Flipper } from "react-flip-toolkit";
import nextArrow from "../../../_asset/images/works/orange-arrow-icon.svg";
import WorksServive from "../../../Modules/API/work.service";
import { CATEGORIES } from "../../../Modules/Contant";
const Grid = () => {
  const LIMIT_WORKS = 100;
  const [limit, setLimit] = useState(1);
  const [categoryCount, setCategoryCount] = useState(0);
  const [category, setCategory] = useState("All");
  const [images, setImages] = useState([]);
  const [fullScreenIndex, setFullScreenIndex] = useState(-1);

  const toggleFullScreen = (index) => {
    if (fullScreenIndex === index) {
      setFullScreenIndex(-1);
    } else {
      setFullScreenIndex(index);
    }
  };
  const countWorks = useMemo(() => {
    if (category !== "All") {
      return images.filter((item) => item.category === category).length;
    }
    return images.length;
  }, [category, images]);

  const handleLoadMore = () => {
    setLimit((prevState) => prevState + 1);
  };

  const fetchData = async () => {
    try {
      const response = await WorksServive.getWorks();
      setImages(response);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };
  useEffect(() => {
    if (categoryCount !== 0) {
      setImages(DisplayData.list);
    }
  }, [categoryCount]);
  useEffect(() => {
    fetchData();
  }, []);

  const renderContents = (contents, category) => {
    let updatedContents = [...contents];
    if (category && category !== "All") {
      updatedContents = updatedContents.filter(
        (content) => content.category === category
      );
    }
    return [...updatedContents]
      .splice(0, limit * LIMIT_WORKS)
      .map((e, index) => {
        const isFullScreen = fullScreenIndex === index;
        return (
          <MUGrid item xs={6} md={4} key={index}>
            <Flipper flipKey={isFullScreen}>
              {isFullScreen ? (
                <FullScreenSquare
                  item={e}
                  toggleFullScreen={() => toggleFullScreen(index)}
                />
              ) : (
                <Square
                  item={e}
                  toggleFullScreen={() => toggleFullScreen(index)}
                />
              )}
            </Flipper>
          </MUGrid>
        );
      });
  };
  return (
    <>
      <div className='work__grid'>
        <MenuTab
          category={category}
          setCategory={setCategory}
          setCategoryCount={setCategoryCount}
          countWorks={countWorks}
        />
        <div className='work__list'>
          <MUGrid container={true} spacing={2}>
            {renderContents(images, category)}
          </MUGrid>
          {limit * LIMIT_WORKS < images.length && (
            <div className='load-more' onClick={handleLoadMore}>
              <div className='load-more__text'>VIEWMORE</div>
              <img src={nextArrow} alt='' />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Grid;
