import React, { useState, useEffect } from "react";
import Cover from "./function/cover";
import "./Emart.scss";
import Eoverflow from "./function/Eoverflow";
import Einterview from "./function/Einterview";
import Appweb from "./function/Appweb";
import Edata from "./function/Edata";
import Edirection from "./function/Edirection";
import Econcept from "./function/Econcept";
import Elayout from "./function/Elayout";
import Esplash from "./function/Esplash";
import Equick from "./function/Equick";
import Ememo from "./function/Ememo";
import Ecatalogue from "./function/Ecatalogue";
import Eevent from "./function/Eevent";
import Emusic from "./function/Emusic";
import Ecoupon from "./function/Ecoupon";
import Eelement from "./function/Eelement";
import Ename from "./function/Ename";

const Emart = () => {
  const [pageY, setPageY] = useState(0);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "GodthamRoundedTTF";
    const handleScroll = () => {
      const { pageYOffset } = window;
      setPageY(pageYOffset);
      // console.log(pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="Emart_container">
      <Cover></Cover>
      <Eoverflow pageY={pageY}></Eoverflow>
      <Einterview pageY={pageY}></Einterview>
      <Appweb pageY={pageY}></Appweb>
      <Edata pageY={pageY}></Edata>
      <Edirection pageY={pageY}></Edirection>
      <Econcept pageY={pageY}></Econcept>
      <Elayout pageY={pageY}></Elayout>
      <Esplash pageY={pageY}></Esplash>
      <Equick pageY={pageY}></Equick>
      <Ememo pageY={pageY}></Ememo>
      <Ecatalogue pageY={pageY}></Ecatalogue>
      <Eevent pageY={pageY}></Eevent>
      <Emusic pageY={pageY}></Emusic>
      <Ecoupon pageY={pageY}></Ecoupon>
      <Eelement pageY={pageY}></Eelement>
      <Ename pageY={pageY}></Ename>
    </div>
  );
};

export default Emart;
