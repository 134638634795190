import React, { Component } from 'react'
import Header from '../../Common/Header/Header'
import MainBoxGrvina from '../../Home/MainLinkboxGrvina/MainLinkBoxGrvina.js'
import FooterNav from '../../Common/FooterNav/FooterNav'
import Canvas from '../../Common/Canvas/Canvas'
import Control from '../../Common/Control/Control'
import HeaderGrvina from '../../Common/HeaderGrvina/HeaderGrvian.js'

class RouterGrvina extends Component {
  state = {
    currentPage: 1,
    isLogoBlack: false,
  }

  toNextPage = () => {
    if (this.state.currentPage < 5) {
      console.log(this.props.history)
      this.props.history.replace(
        this.renderPagePath(this.state.currentPage + 1),
      )
      this.setState({ currentPage: this.state.currentPage + 1 })
    }
  }

  toPrevPage = () => {
    if (this.state.currentPage > 1) {
      this.props.history.replace(
        this.renderPagePath(this.state.currentPage - 1),
      )
      this.setState({ currentPage: this.state.currentPage - 1 })
    }
  }

  renderPagePath = (num) => {
    console.log(num)
    if (num === 1) {
      return '/#firstpage'
    } else if (num === 2) {
      this.setState({ isLogoBlack: true })
      return '/#secondpage'
    } else if (num === 3) {
      return '/#thirdpage'
    } else if (num === 4) {
      this.setState({ isLogoBlack: true })
      return '/#fourpage'
    } else if (num === 5) {
      return '/#fivepage'
    }
  }

  handleHeaderChange = (index) => {
    if (
      index === 2 ||
      index === 4 ||
      index === 6 ||
      index === 7 ||
      index === 8 ||
      index === 10
    ) {
      this.setState({ isLogoBlack: true })
    } else {
      this.setState({ isLogoBlack: false })
    }
    this.setState({
      currentPage: index,
    })
  }

  render() {
    const { currentPage } = this.state
    return (
      <div>
        {/* <Canvas></Canvas> */}
        <HeaderGrvina
          isLogoBlack={this.state.isLogoBlack}
          currentPage={currentPage}
        ></HeaderGrvina>
        <MainBoxGrvina
          handleHeaderChange={this.handleHeaderChange}
          history={this.props.history}
        ></MainBoxGrvina>
      </div>
    )
  }
}

export default RouterGrvina
