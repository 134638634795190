import React, { Component } from 'react'
import './Style/ForthPage.scss'
import arrow from '../../_asset/images/mainback/Arrow.png'
import ic01 from '../../_asset/images/grvina/04-ic-01@3x.png'
import ic02 from '../../_asset/images/grvina/04-ic-02@3x.png'
import ic03 from '../../_asset/images/grvina/04-ic-03.png'
import ic04 from '../../_asset/images/grvina/04-ic-04@3x.png'
import ic05 from '../../_asset/images/grvina/04-ic-05@3x.webp'

class ForthPage extends Component {
  render() {
    return (
      <div className="ForthPageGrvina">
        <div className="ForthPageGrvina__title">GR VINA 베트남 공장정보</div>
        <div className="ForthPageGrvina__desc">
          베트남 국내법과 국제 공장관리 규정을 준수하고 있으며,
        </div>
        <div className="ForthPageGrvina__desc">
          미국 고품질 브랜드와 일본 유수의 기업들의 품질 및 관리 기준을 충족
          시키고 있습니다.
        </div>

        <div className="ForthPageGrvina__content1">
          <div className="ForthPageGrvina__content1-item">
            <img className="ForthPageGrvina__content1-img" src={ic01} alt="" />
            <div className="ForthPageGrvina__content1-text1">월 생산량</div>
            <div className="ForthPageGrvina__content1-text2">1,200,000족</div>
            <div className="ForthPageGrvina__content1-text3">
              일반 남성용 양말기준
            </div>
          </div>
          <div className="ForthPageGrvina__content1-item">
            <img className="ForthPageGrvina__content1-img" src={ic02} alt="" />
            <div className="ForthPageGrvina__content1-text1">기계 대수</div>
            <div className="ForthPageGrvina__content1-text2">229대</div>
            <div className="ForthPageGrvina__content1-text3"></div>
          </div>
        </div>

        <div className="ForthPageGrvina__content2">
          <div className="ForthPageGrvina__content2-item">
            <img className="ForthPageGrvina__content2-img" src={ic03} alt="" />
            <div className="ForthPageGrvina__content2-text1">직원 수</div>
            <div className="ForthPageGrvina__content2-text2">200명</div>
          </div>
          <div className="ForthPageGrvina__content2-item">
            <img className="ForthPageGrvina__content2-img" src={ic04} alt="" />
            <div className="ForthPageGrvina__content2-text1">공장면적</div>
            <div className="ForthPageGrvina__content2-text2">8,000m2</div>
          </div>
          <div className="ForthPageGrvina__content2-item">
            <img className="ForthPageGrvina__content2-img5" src={ic05} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default ForthPage
