import React, { Component } from 'react'
import Lottie from 'react-lottie'
import './Style/FifthPage.scss'
import '../../Common/LinkClick/LinkClick.scss'
import img01 from '../../_asset/images/grvina/05-img-01@3x.webp'
import img02 from '../../_asset/images/grvina/05-img-02@3x.webp'
import img03 from '../../_asset/images/grvina/05-img-03@3x.webp'
import img04 from '../../_asset/images/grvina/05-img-04@3x.webp'
import img05 from '../../_asset/images/grvina/05-img-05@3x.webp'
import img06 from '../../_asset/images/grvina/05-img-06@3x.webp'
import iconLeft from '../../_asset/images/grvina/05-ic-arrow-l@3x.png'
import iconRight from '../../_asset/images/grvina/05-ic-arrow-r@3x.png'
import iconCircle from '../../_asset/images/grvina/05-ic-circle-2@3x.png'
import textArrow from '../../_asset/images/grvina/05-text-arrow@3x.png'
import Slider from 'react-slick'

// console.log(this.props.testPage);
const dataSilde = [
  {
    id: 0,
    title: 'KNITTING  |  편직',
    img: img01,
  },
  {
    id: 1,
    title: 'ROSSO  |  봉조',
    img: img02,
  },
  {
    id: 2,
    title: 'SETTING  |  셋팅',
    img: img03,
  },
  {
    id: 3,
    title: 'PACKING  |  가공',
    img: img04,
  },
  {
    id: 4,
    title: 'INSPECTION  |  검사',
    img: img05,
  },
  {
    id: 5,
    title: 'FINISHING  |  선적준비',
    img: img06,
  },
]
class FifthPage extends Component {
  state = {
    activeSlide: 0, // Initialize the active slide index
  }
  constructor(props) {
    super(props)
    this.sliderRef = React.createRef()
  }
  renderSliderItems = (items) =>
    items.map(({ img, title, id }, index) => (
      <div className="FifthPageGrvina__slide" key={`${id}-${index}`}>
        <img className="FifthPageGrvina__slide-img" src={img} alt="" />
      </div>
    ))
  handleAfterChange = (currentSlide) => {
    // Update the state with the index of the currently active slide
    this.setState({ activeSlide: currentSlide })
  }
  handleItemClick = (index) => {
    // Set the active slide index in the state
    this.setState({ activeSlide: index })

    // Use the slickGoTo method to change the active slide
    this.sliderRef.current.slickGoTo(index)
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: (
        <img
          className="FifthPageGrvina__leftArrow"
          style={{ width: 62 }}
          src={iconRight}
          alt="RightArrow"
        />
      ),
      prevArrow: (
        <img
          className="FifthPageGrvina__rightArrow"
          style={{ width: 62 }}
          src={iconLeft}
          alt="LeftArrow"
        />
      ),
      afterChange: this.handleAfterChange,
    }

    const { activeSlide } = this.state
    return (
      <section className="FifthPageGrvina">
        <div className="FifthPageGrvina__heading1">베트남 공장정보</div>
        <div className="FifthPageGrvina__heading2">GR VINA</div>
        <div className="FifthPageGrvina__content">
          <img
            className="FifthPageGrvina__content-icon"
            src={iconCircle}
            alt=""
            style={{ top: 20 + 62 * activeSlide }}
          />
          <div className="FifthPageGrvina__content-left">
            <div className="FifthPageGrvina__line"></div>
            {dataSilde.map((item, key) => (
              <>
                <div
                  className="FifthPageGrvina__item"
                  key={item.id}
                  onClick={() => this.handleItemClick(item.id)}
                >
                  <div
                    className={
                      activeSlide === item.id
                        ? 'FifthPageGrvina__title FifthPageGrvina__title-active'
                        : 'FifthPageGrvina__title'
                    }
                  >
                    {item.title}
                  </div>
                  <img
                    className="FifthPageGrvina__arrow"
                    alt=""
                    src={textArrow}
                  ></img>
                </div>
                <div className="FifthPageGrvina__line"></div>
              </>
            ))}
          </div>
          <div className="FifthPageGrvina__content-right">
            <Slider ref={this.sliderRef} {...settings}>
              {this.renderSliderItems(dataSilde)}
            </Slider>
            <div className="FifthPageGrvina__desc">
              {dataSilde.find((i) => i.id === activeSlide).title}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FifthPage
