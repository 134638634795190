import React, { Component } from 'react';
import './HeaderLogo.scss';
import logo from "../../_asset/images/mainback/logo-white-icon.svg"
import logoBlack from "../../_asset/images/mainback/logo-black-icon.svg"
import { Link } from 'react-router-dom';

class HeaderLogo extends Component {
    render() {
        const { isLogoBlack } = this.props;
        const headerClassName = isLogoBlack ? "HeaderLogoMain black" : "HeaderLogoMain"
        return (
            <div className={headerClassName}><a href ="/#firstpage">
                <img src={isLogoBlack ? logoBlack : logo} alt='Logo' /></a>
            </div>
        );
    }
}

export default HeaderLogo;