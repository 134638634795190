import React, { Component } from 'react';
import "./Style/SharePage.scss"
import { makeStyles } from '@material-ui/core/styles';
import WhiteButton from '../../../../src/_asset/images/mainback/white-download-icon.svg'
import BlackButton from '../../../../src/_asset/images/mainback/black-download-icon.svg'
import { Container, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

class SharePage extends Component {
    state = {
        agreeState: false,
        ComBtn: false,
        WebBtn: false,
        BrandingBtn: false,
        fitstList: [
            {
                id: 1,
                title: "Annual Operation",
            },
            {
                id: 2,
                title: " Reactive Web",
            },
            {
                id: 3,
                title: " Photography",
            },
            {
                id: 4,
                title: "Search Engin Optimization (SEO)",
            },



        ],
        secondList: [
            {
                id: 5,
                title: "Character Design",
            },
            {
                id: 6,
                title: "Illustration Design",
            },
            {
                id: 7,
                title: "Search Engine Optimization (SEO)",
            },
            {
                id: 8,
                title: "Character Design",
            },
        ],
        thirdList: [
            {
                id: 9,
                title: "Development of WordPress Custom Theme",
            },
            {
                id: 10,
                title: "Maintenance",
            },
            {
                id: 11,
                title: "Shopping mall",
            },
            {
                id: 12,
                title: "Other",
                subtitle: "*Please write down the details in detail",
            },
        ],

        checkedList: []
    }
    // classes = useStyles();
    handleAgreeCheck = () => {
        if (this.state.agreeState) {
            this.setState({ agreeState: false })
        } else {
            this.setState({ agreeState: true })
        }
    }
    handleCheckedItem = (value) => {

        if (
            !this.state.checkedList.find((item) => item === value)
        ) {
            let checkedList = this.state.checkedList;
            checkedList.push(value);
            this.setState({ checkedList });
        } else {
            let checkedList = this.state.checkedList;
            checkedList = checkedList.filter((item) => item !== value);
            this.setState({ checkedList });
        }
    };
    render() {
        return (
            <div>
                <div className='ReauestImgBox'>
                    <div className='ReauestTopBox'>
                        <div className='ReauestText'>
                            SHARE<br />
                                    CREATIVE<br />
                                    IDEAS.
                            </div>
                        <div className='TobContainer'>

                            <div className='ReauestExplanationText'>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <div className='CompanyLetter'>
                                            <div className='FirstTextBox'>
                                                COMPANY<br />
                                            LETTER
                                        </div>

                                            <div className='Button'
                                                onMouseDown={() => this.setState({ ComBtn: true })}
                                                onMouseUp={() => this.setState({ ComBtn: false })} >
                                                <img className="WhiteButton" src={this.state.ComBtn ? BlackButton : WhiteButton} alt='WhiteButton' />
                                            DOWN LOAD
                                        </div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='Web_Mobile'>
                                            <div className='SecondTextBox'>
                                                WEB / MOBILE<br />
                                            EXAMPLE
                                        </div>

                                            <div className='Button'
                                                onMouseDown={() => this.setState({ WebBtn: true })}
                                                onMouseUp={() => this.setState({ WebBtn: false })} >

                                                <img className="WhiteButton" src={this.state.WebBtn ? BlackButton : WhiteButton} alt='WhiteButton' />
                                            DOWN LOAD

                                        </div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='Branding'>
                                            <div className='ThirdTextBox'>
                                                BRANDING<br />
                                            EXAMPLE
                                        </div>

                                            <div className='Button' onMouseUp={() => this.setState({ BrandingBtn: false })}
                                                onMouseDown={() => this.setState({ BrandingBtn: true })}>
                                                <img className="WhiteButton" src={this.state.BrandingBtn ? BlackButton : WhiteButton} alt='WhiteButton' />
                                            DOWN LOAD
                                        </div>
                                        </div>

                                    </Grid>
                                </Grid>


                            </div>

                            <div className='TextContainer'>
                                Trusted developer, UIX. if you rely on UIX for the<br />
                                    project, we will reply to you within <span>3 days</span> after<br />
                                    confirmation. UIX always think.<br />
                                    Try to understand and empathize with your cus-<br />
                                    tomers and share your creative ideas.
                                </div>

                            <div className='EmailContainer'>
                                uix.support@gmail.com
                                </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SharePage;