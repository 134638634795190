import React, { useState, useEffect } from "react";
import "./style/Einterview.scss";
import styled from "styled-components";

let list = [
  {
    text: "1.이마트 앱은 이마트를 위한 마케팅에 주력",
  },
  {
    text: "2.생활의 일부, 습관이 되는 앱, 매장 필수 앱",
  },
  {
    text: "3.메뉴가 인&아웃되어 정리되지 않았음",
  },
  {
    text: "4.목표 고객층은 오프라인 주요 고객인 30-50대",
  },
  {
    text: "5.모바일 웹은 검색 유입, 비로그인 기반",
  },
  {
    text: "6.영업시간, 영업일",
  },
  {
    text: "7.콘텐츠 운영:홈 2-3일, 이벤트 1주, 전단 2주, 신선식품 1주",
  },
  {
    text: "8.SNS 콘텐츠는 앱에 맞게 재가공하여 업로드했으면 함",
  },
  {
    text: "9.고객들은 모바일 전단보다 pdf 전단으로 보기를 선호",
  },
  {
    text: "10.바이어들은 상품에 대한 피드백을 얻고 싶어함",
  },
];

const Title = styled.div`
  overflow: hidden;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const InterImage = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1.2s linear;
  width: 480px;
  height: 775px;
  margin: 0 auto;
`;

const Einterview = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 2000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Einterviewcontainer">
      <div className="con_bx">
        <div className="Einterviewcontainer-content row">
          <div className="Einterviewcontainer-item col col-6">
            <div className="Einterviewcontainer-wrapper">
              <Title trigger={trigger}>
                <div className="Einter_subtitle">CLIENT INTERVIEW</div>
                <div className="Einter_title">
                  이마트 현업 실무자를 <br></br>
                  대상으로 인터뷰를 <br></br>
                  진행하고 현재 이마트의 <br></br>
                  현황을 파악했습니다.
                </div>
                <div className="Einter_text">
                  {list.map((item, index) => {
                    return (
                      <div className="List_text" key={index}>
                        {item.text}
                      </div>
                    );
                  })}
                </div>
              </Title>
            </div>
          </div>
          <div className="Einterviewcontainer-item col col-6">
            <div className="Einterviewcontainer-wrapper">
              <InterImage trigger={trigger}>
                <div className="con_txt">
                  <div className="text1">
                    이마트 앱은 이마트를<br></br>
                    위한 마케팅에 주력하고<br></br>
                    싶습니다.
                  </div>
                  <div className="text2">
                    생활의 일부, 습관이 <br></br>
                    되는 앱, 매장 필수 앱이 <br></br>
                    목표입니다.
                  </div>
                  <div className="text3">
                    목표 고객층은<br></br>
                    오프라인 주요 고객인 <br></br>
                    30-50대 입니다.
                  </div>
                  <div className="text4">
                    모바일 웹은 검색 유입, <br></br>
                    비로그인 기반입니다.
                  </div>
                </div>
              </InterImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Einterview;
