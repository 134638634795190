import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/Ememo.scss";
import gifimage from "../../../../_asset/images/emart/shopping_memo.gif";

const Title = styled.div`
  font-size: 50px;
  font-weight: 600;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Gif = styled.div`
  display: inline-block;
  overflow: hidden;
  margin-top: 150px;
  border-radius: 50px;
  box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.2s linear;
  transition: transform 1.2s linear;
  > img {
    width: 448px;
    height: 928px;
  }
`;

const Ememo = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 18500) {
      setTrigger(true);
    }
  });
  return (
    <div className="Ememocontainer">
      <Title trigger={trigger}>Shopping Memo</Title>
      <Gif trigger={trigger}>
        <img src={gifimage} alt="memo_image" />
      </Gif>
    </div>
  );
};

export default Ememo;
