import React from "react";
import "./style/Pmotif.scss";

const Pmotif = () => {
  return (
    <div className="Pmotif_container">
      <div className="Pmotif_cont_1">
        <div className="Pmorif_txt_bx">
          <div className="Pmorif_tit">Motif</div>
          <div className="Pmorif_txt">
            프로레나타의 모티브 요소는 브랜드 컨셉인 '처방전'으로부터
            시작합니다. <br></br>
            처방전을 연상할 수 있는 레이아웃과, 실제 의사가 처방해주는 듯한
            핸드라이팅 서체를 브랜드 디자인 전반에 활용합니다.
          </div>
        </div>
      </div>
      <div className="Pmotif_cont_2"></div>
      <div className="Pmotif_cont_3"></div>
      <div className="Pmotif_cont_4">
        <div className="Left_cont"></div>
        <div className="Right_cont"></div>
      </div>
    </div>
  );
};

export default Pmotif;
