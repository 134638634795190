import React, { Component } from "react";
import G9 from "../../Experience/Contents/G9/G9";

class G9Router extends Component {
  render() {
    return (
      <div>
        <G9></G9>
      </div>
    );
  }
}

export default G9Router;
