import React from "react";
import "./style/Pdifferentiation.scss";
import differ01 from "../../../../_asset/images/Prorenata/img_prn6.png";

const Pdifferentiation = () => {
  return (
    <div className="Pdiffer_container">
      <div className="Pdiffer_cont">
        <div className="Pdiffer_txt_box">
          <div className="tit">Brand Differentiation</div>
          <div className="txt">
            대부분 경쟁사 브랜드들은 원료와 기술력,<br></br>
            오랜 경력의 피부과 전문의 등 'Professional'에<br></br>
            초점을 맞추었다면, PRO RE NATA는 제품<br></br>
            성분과 효능에 대한 집착, 품질 높은 제품에<br></br>
            대한 자부심, 고객의 건강한 삶에 대한 진정성<br></br>등
            'Authenticity'를 브랜드 핵심 키워드로<br></br>
            설정하여 진정성 있는 브랜드 철학으로 브랜드<br></br>
            차별화를 소구하였습니다.
          </div>
        </div>
        <div className="differ_image">
          <img src={differ01} alt="differ_image" />
        </div>
      </div>
    </div>
  );
};

export default Pdifferentiation;
