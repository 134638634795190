import React, { useState, useEffect } from "react";
import "./style/Ecoupon.scss";
import styled from "styled-components";
import coupontit from "../../../../_asset/images/emart/tit_coupon.png";
import couponimg01 from "../../../../_asset/images/emart/img_coupon1.png";
import couponimg02 from "../../../../_asset/images/emart/img_coupon2.png";
import couponimg03 from "../../../../_asset/images/emart/img_coupon3.png";
import couponimg04 from "../../../../_asset/images/emart/img_coupon4.png";
import couponimg05 from "../../../../_asset/images/emart/img_coupon5.png";

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Frame = styled.div`
  display: block;
  margin-bottom: 10px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    position: relative;
    border-radius: 50px;
    box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
  }
`;

const CouponList = styled.div`
  margin: 262px 0 0 0;
  text-align: center;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
  > img {
    position: relative;
    border-radius: 50px;
    box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
  }
  @media screen and (max-width: 992px) {
    margin: 0;
  }
`;

const Ecoupon = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 26000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Ecouponcontainer">
      <div className="con_bx">
        <Title trigger={trigger}>
          <img src={coupontit} alt="coupon_title" />
          <div className="coupon_sub">
            분야에 상관없이 할인이 적용될 쿠폰이 있는지 시간순으로 <br></br>
            전체를 훑어보는 소비 행태에 따라 한 곳에 보관할 수 있게 했습니다.
          </div>
        </Title>
        <div className="list row">
          <div className="list-item col col-6">
            <div className="list-wrapper">
              <div className="coupon_list01">
                <div className="coupon_Wrap">
                  <Frame trigger={trigger}>
                    <img src={couponimg01} alt="coupon_image01" />
                  </Frame>
                  <img src={couponimg02} alt="coupon_image02" />
                  <img src={couponimg03} alt="coupon_image03" />
                  <img src={couponimg04} alt="coupon_image04" />
                </div>
              </div>
            </div>
          </div>
          <div className="list-item col col-6">
            <div className="list-wrapper">
              <CouponList trigger={trigger}>
                <img src={couponimg05} alt="coupon_image05" />
              </CouponList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecoupon;
