import React, { useState, useEffect } from "react";
import "./NoticeGrid.scss";
import DisplayData from "../../../Common/Id/NoticeId/index";
import TestBox from "../../../Test/test";
import ContentDialog from "./ContentDialog/ContentDialog";
import { Grid } from "@material-ui/core";
import { Link } from 'react-router-dom'
const imageDir = "/_asset/images/works/thumb/";

const NoticeGrid = () => {
	const [images, setImages] = useState([]);
	useEffect(() => {
		setImages(DisplayData.list);
	}, []);
	const renderContents = (contents) => (
		contents.map((e, i) => (
			<Grid item xs={12} sm={6} md={4}>
				<div key={i} className="experience__item bx active visible">
					{/* <div className="LinkImgBox"> */}
					<a className="item__link">
						<div className="item__frame">
							<Link to={`/news/${e.id}`}>
								<div
									className="item__dimd"
									style={{
										// backgroundColor: "rgb(255, 64, 1)",
										animation:
											"360ms cubic-bezier(0.215, 0.61, 0.355, 1) 50ms 1 normal both running slideOutDown",
									}}>

									<img src={e.img} alt="thumbnail" />
									{/* <div className="FontBox">비둘기를 잡아보자</div> */}
									<div className='NoticeTextBox'>
										<div className='TextTitle'>
											{e.title}
										</div>
										<div className='TextSub'>
											{e.sub}
										</div>
										<div className='TextSort'>
											{e.sort}
										</div>
									</div>
								</div>
							</Link>
						</div>
					</a>
					{/* </div> */}
				</div>
			</Grid>

		))
	)
	return (
		<>
			<div className='NoticeGridMain'>
				<div className="experience__grid">
					<div className="experience__list" style={{ maxWidth: "1680px" }}>
						<div className='NoticeTitle'>
							Notice
					</div>
						<div className='NoticeContents'>
							<Grid container spacing={2}>
								{renderContents(images)}
							</Grid>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	// console.log(images.filter((e) => e.category === category));
};

export default NoticeGrid;
