import React from "react";
import "./style/Appweb.scss";
import apptit from "../../../../_asset/images/emart/tit_app.png";
import apptit_s from "../../../../_asset/images/emart/tit_app_pageview.png";

const Appweb = () => {
  return (
    <div className="AppWebcontainer">
      <div className="con_bx">
        <div className="tit_bx">
          <div className="tit_img">
            <img src={apptit} alt="App_tit" />
          </div>
          <div className="txt">
            <div className="s_tit">
              <img src={apptit_s} alt="App_tit_s" />
            </div>
            <div className="s_txt">
              App에서는 쇼핑 정보 획득에 대한 과업이 1순위로 고려되어야 합니다.
            </div>
          </div>
        </div>
        <div className="graph">
          <ul className="graph_ul">
            <li style={{ marginTop: "32px" }}>
              <p
                style={
                  ({ overflow: "hidden" },
                  { color: "#222" },
                  { fontSize: "14px" })
                }
              >
                첫페이지
              </p>
              <div className="bar_wrap_bar1">
                <div className="bar_area">
                  <div className="bar_skrollable"></div>
                  <span className="color_skrollable"></span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Appweb;
