import React, { Component } from "react";
import "./Style/FirstPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import backimg from "../../_asset/images/mainback/bg-01@3x.png";
class FirstPage extends Component {
  render() {
    return (
      <div
        className="FirstPage"
        style={{ width: "100%", height: "100%", color: "#FFF" }}
      >
        <div className="U">M</div>
        <div className="I">A</div>
        <div className="X">D</div>
        <div className="uix-always-thinks">
          <span className="uix">Don't Stop</span>
          <br />
          <span className="always">Moving</span>
          <br />
          <span className="thinks">Creatively</span>
        </div>
        {/* <div className="BackImg">
					<img src={backimg} alt='Background-image'/>
				</div> */}
      </div>
    );
  }
}

export default FirstPage;
