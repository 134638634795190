import React, { Component } from "react";
import "./Style/FifthPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import img_bg from "../.././_asset/images/mainback/bg-05@3x.webp";

class FifthPage extends Component {
  handleClickBoard = () => {
    navigator.clipboard.writeText("inform.no.mad@gmail.com");
    alert("이메일이 복사되었습니다.");
  };
  render() {
    return (
      <div
        className="FifthPage"
        style={{ width: "100%", height: "100%", color: "#FFF" }}
      >
        <div className="FifthPage-bg">
          <img src={img_bg} alt="" />
        </div>
        <div className="Link" onClick={this.handleClickBoard}>
          <div className="LinkClickContainer"></div>
        </div>
      </div>
    );
  }
}

export default FifthPage;
