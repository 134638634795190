import React, { useEffect } from "react";
import Kaward from "./function/Kaward";
import Kcover from "./function/Kcover";
import Ksection01 from "./function/Ksection01";
import Ksection02 from "./function/Ksection02";
const Kaola = () => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "GodthamRoundedTTF";
  });
  return (
    <div className="Kaola_container">
      <Kcover></Kcover>
      <Kaward></Kaward>
      <Ksection01></Ksection01>
      <Ksection02></Ksection02>
    </div>
  );
};

export default Kaola;
