import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import "./MenuTab.scss";
import ScrollContainer from "react-indiana-drag-scroll";
import WorksServive from "../../Modules/API/work.service";
import { CATEGORIES } from "../../Modules/Contant";
const MenuTab = ({
    category,
    setCategory,
    setCategoryCount,
    countWorks,
    location,
}) => {
    // const tabs = [
    //   CATEGORIES.All,
    //   CATEGORIES.Dev,
    //   CATEGORIES.Ad,
    //   CATEGORIES.Cafe,
    //   CATEGORIES.CIBI,
    //   CATEGORIES.IT,
    //   CATEGORIES.Media,
    // ];
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setCategoryCount(tabs.length);
        const queryData = queryString.parse(location.search);
        const id = tabs.indexOf(queryData.id);
        if (id !== -1) {
            setCategory(id);
        }
    }, []);

    const getCategorys = async () => {
        const res = await WorksServive.getCategorys();
        setTabs(["All", ...res.items?.map((item) => item.fields.name)]);
    };
    useEffect(() => {
        getCategorys();
    }, []);

    return (
        <div className="scroll-wrapper">
            <ScrollContainer className="scroll-container">
                <div className="MenuTabContainer" id="MenuTabContainer">
                    <div className="MenuTabMain" id="MenuTabMain">
                        {tabs.map((e, i) => {
                            return (
                                <div
                                    className={
                                        category === e
                                            ? "MenuTabCategoryHover"
                                            : "MenuTabCategory"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setCategory(e);
                                    }}
                                    key={e}
                                >
                                    <div className="NumberCircle">
                                        <a>{countWorks}</a>
                                    </div>
                                    <span className="MenuLTabText">
                                        <span
                                            className="mask-text"
                                            style={{ animationDelay: "0ms" }}
                                        >
                                            <span>{e}</span>
                                        </span>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ScrollContainer>
        </div>
    );
};

export default withRouter(MenuTab);
