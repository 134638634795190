import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/media.scss";
import mediaph from "../../../../_asset/images/29TV/img_media01.png";
import leftimg from "../../../../_asset/images/29TV/img_media02.png";
import mediavd from "../../../../_asset/images/29TV/video_media.mp4";
const Title = styled.div`
  font-size: 55px;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Ullist = styled.div`
  display: flex;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 150px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
`;
const Media = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 10000) {
      setTrigger(true);
    }
  }, [pageY]);
  return (
    <div className="mediacontainer">
      <div className="media_top">
        <Title trigger={trigger}>
          MEDIA <br />
          COMMERCE <br />
          29CM
        </Title>
        <Subtitle trigger={trigger}>
          <span>On boarding</span>
          <br />
          <br />
          심심하면 TV를 켜던 과거의 습관처럼, 언제든 볼 수 있도록 <br />
          GNB에 29TV를 위치시켰습니다.
        </Subtitle>

        <div className="phone_image">
          <img src={mediaph} alt="media_Phone" />
        </div>
        <Ullist trigger={trigger}>
          <div className="leftbox">
            <div className="left_text">
              29CM <br />
              Commerce platform
            </div>
            <div className="left_img">
              <img src={leftimg} alt="media_png" />
            </div>
          </div>
          <div className="rightbox">
            <video className="video" id="media_video" loop autoPlay>
              <source src={mediavd} alt="media_video" />
            </video>
            <div className="right_text">
              29TV <br />
              Media platform
            </div>
          </div>
        </Ullist>
      </div>
    </div>
  );
};

export default Media;
