// import ProjectData from '../../Data/WorksData/index'
// import fullScreenBetterSamsung from '../../Data/WorksData/ProjectData/BetterSamsung/Img/betterSamsung.jpeg'
// import fullScreenTimingDark from '../../Data/WorksData/ProjectData/TimingDark/Img/003.jpg'
// import fullScreenTiming from '../../Data/WorksData/ProjectData/Timing/Img/004.jpg'
// import fullScreenMilkShake from '../../Data/WorksData/ProjectData/MilkShake/Img/009.jpg'
// import fullScreenVictor from '../../Data/WorksData/ProjectData/Victor/Img/001.jpg'
// import fullScreenCircle from '../../Data/WorksData/ProjectData/Circle/Img/006.jpg'
// import fullScreenLyn from '../../Data/WorksData/ProjectData/Lyn/Img/002.jpg'
// import fullScreenSkylife from '../../Data/WorksData/ProjectData/Skylife/Img/007.jpg'
// import fullScreenDaisy from '../../Data/WorksData/ProjectData/Daisy/Img/005.jpg'
// import fullScreenHappened from '../../Data/WorksData/ProjectData/Happened/Img/011.jpg'
// import fullScreenHyundaiRima from '../../Data/WorksData/ProjectData/Hyundai_rima/Img/010.jpg'
// import fullScreenPriest from '../../Data/WorksData/ProjectData/Priest/Img/008.jpg'
// import tv29 from "../../Data/WorksData/ProjectData/Image/1@2x.png";
// import BetterSamsung from "../../Data/WorksData/ProjectData/Image/BetterSamsung.jpg";
// import TimingDark from "../../Data/WorksData/ProjectData/Image/TimingDark.jpg";
// import Timing from "../../Data/WorksData/ProjectData/Image/Timing.jpg";
// import MilkShake from "../../Data/WorksData/ProjectData/Image/MilkShake.jpg";
// import Victor from "../../Data/WorksData/ProjectData/Image/Victor.jpg";
// import Circle from "../../Data/WorksData/ProjectData/Image/Circle.jpg";
// import Lyn from "../../Data/WorksData/ProjectData/Image/Lyn.jpg";
// import Skylife from "../../Data/WorksData/ProjectData/Image/Skylife.jpg";
// import Daisy from "../../Data/WorksData/ProjectData/Image/Daisy.jpg";
// import Happened from "../../Data/WorksData/ProjectData/Image/Happened.jpg";
// import Hyundai_rima from "../../Data/WorksData/ProjectData/Image/Hyundai_rima.jpg";
// import Priest from "../../Data/WorksData/ProjectData/Image/Priest.jpg";
//////////////////////////////////스포츠 몬스터 이미지////////////////////////////////////////////////
import SportsMonsterThum from "../../Data/WorksData/ProjectData/SportsMonster/Img/thumnailimg.png";
import SportsMonster1Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-01.png";
import SportsMonster2Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-02.png";
import SportsMonster3Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-03.png";
// import SportsMonster4Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-04.png';
// import SportsMonster5Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-05.png';
// import SportsMonster6Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-06.png';
// import SportsMonster7Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-07.png';
// import SportsMonster8Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-08.png';
import SportsMonster9Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-09.png";
import SportsMonster10Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-10.png";
// import SportsMonster11Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-11.png';
import SportsMonster12Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-12.png";
// import SportsMonster13Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-13.png';
import SportsMonster14Img from "../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-14.png";
// import SportsMonster15Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-15.png';
// import SportsMonster16Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-16.png';
// import SportsMonster17Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-17.png';
// import SportsMonster18Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-18.png';
/////////////////////////////////엘지 디스플레이 이미지/////////////////////////////////////////////////
import LGDisplayThum from "../../Data/WorksData/ProjectData/LGDisplay/Img/thumnailimg.png";
import LGDisplay1Img from "../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-01.png";
import LGDisplay2Img from "../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-02.png";
// import LGDisplay3Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-03.png';
import LGDisplay4Img from "../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-04.png";
// import LGDisplay5Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-05.png';
// import LGDisplay6Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-06.png';
import LGDisplay7Img from "../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-07.png";
// import LGDisplay8Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-08.png';
// import LGDisplay9Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-09.png';
// import LGDisplay10Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-10.png';
import LGDisplay11Img from "../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-11.png";
// import LGDisplay12Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-12.png';
// import LGDisplay13Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-13.png';
/////////////////////////////////의왕레일파크 이미지////////////////////////////////////////////////////
import RailParkThum from "../../Data/WorksData/ProjectData/RailPark/Img/thumnailimg.png";
import RailPark1Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-01.png";
import RailPark2Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-02.png";
import RailPark3Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-03.png";
// import RailPark4Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-04.png';
import RailPark5Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-05.png";
import RailPark6Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-06.png";
// import RailPark7Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-07.png';
import RailPark8Img from "../../Data/WorksData/ProjectData/RailPark/Img/mainimg-08.png";
// import RailPark9Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-09.png';
// import RailPark10Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-10.png';
/////////////////////////////////삼성SDS 이미지////////////////////////////////////////////////////
import SamsungSdsThum from "../../Data/WorksData/ProjectData/SamsungSds/Img/thumnailimg.png";
import SamsungSds1Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-01.png";
import SamsungSds2Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-02.png";
import SamsungSds3Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-03.png";
import SamsungSds4Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-04.png";
import SamsungSds5Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-05.png";
import SamsungSds6Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-06.png";
import SamsungSds7Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-07.png";
import SamsungSds8Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-08.png";
import SamsungSds9Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-09.png";
import SamsungSds10Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-10.png";
import SamsungSds11Img from "../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-11.png";
/////////////////////////////////Galleria//////////////////////////////////////////////////////////
import GalleriaThum from "../../Data/WorksData/ProjectData/Galleria/Img/thumnailimg.png";
import Galleria1Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-01.png";
import Galleria2Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-02.png";
import Galleria3Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-03.png";
import Galleria4Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-04.png";
import Galleria5Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-05.png";
import Galleria6Img from "../../Data/WorksData/ProjectData/Galleria/Img/mainimg-06.png";
/////////////////////////////////SkyPark//////////////////////////////////////////////////////////
import SkyParkThum from "../../Data/WorksData/ProjectData/SkyPark/Img/thumnailimg.png";
import SkyPark1Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-01.png";
import SkyPark2Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-02.png";
import SkyPark3Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-03.png";
// import SkyPark4Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-04.png';
import SkyPark5Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-05.png";
import SkyPark6Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-06.png";
import SkyPark7Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-07.png";
// import SkyPark8Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-08.png';
import SkyPark9Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-09.png";
import SkyPark10Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-10.png";
// import SkyPark11Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-11.png';
// import SkyPark12Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-12.png';
import SkyPark13Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-13.png";
// import SkyPark14Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-14.png';
// import SkyPark15Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-15.png';
// import SkyPark16Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-16.png';
// import SkyPark17Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-17.png';
// import SkyPark18Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-18.png';
// import SkyPark19Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-19.png';
// import SkyPark20Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-20.png';
// import SkyPark21Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-21.png';
// import SkyPark22Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-22.png';
// import SkyPark23Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-23.png';
// import SkyPark24Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-24.png';
// import SkyPark25Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-25.png';
// import SkyPark26Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-26.png';
import SkyPark27Img from "../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-27.png";
// import SkyPark28Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-28.png';
// import SkyPark29Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-29.png';
// import SkyPark30Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-30.png';
// import SkyPark31Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-31.png';
// import SkyPark32Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-32.png';
// import SkyPark33Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-33.png';
// import SkyPark34Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-34.png';
// import SkyPark35Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-35.png';
// import SkyPark36Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-36.png';
// import SkyPark37Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-37.png';
// import SkyPark38Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-38.png';
// import SkyPark39Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-39.png';
// import SkyPark40Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-40.png';
// import SkyPark41Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-41.png';
// import SkyPark42Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-42.png';
// import SkyPark43Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-43.png';
// import SkyPark44Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-44.png';
// import SkyPark45Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-45.png';
// import SkyPark46Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-46.png';
/////////////////////////////////Jurassic//////////////////////////////////////////////////////////
import JurassicThum from "../../Data/WorksData/ProjectData/Jurassic/Img/thumnailimg.png";
import Jurassic1Img from "../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-01.png";
import Jurassic2Img from "../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-02.png";
// import Jurassic3Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-03.png';
// import Jurassic4Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-04.png';
// import Jurassic5Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-05.png';
/////////////////////////////////KimMuseum//////////////////////////////////////////////////////////
import KimChungUpMuseumThum from "../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/thumnailimg.png";
import KimChungUpMuseum1Img from "../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-01.png";
import KimChungUpMuseum2Img from "../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-02.png";
import KimChungUpMuseum3Img from "../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-03.png";
/////////////////////////////////Samsung SDS 3면영상//////////////////////////////////////////////////////////
import SamsungSds2Thum from "../../Data/WorksData/ProjectData/SamsungSds2/Img/thumnailimg.png";
import SamsungSds21Img from "../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-01.png";
import SamsungSds22Img from "../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-02.png";
import SamsungSds23Img from "../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-03.png";
/////////////////////////////////중국 정저우 도서관//////////////////////////////////////////////////////////
import ChinaLibraryThum from "../../Data/WorksData/ProjectData/ChinaLibrary/Img/thumnailimg.png";
import ChinaLibrary1Img from "../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-01.png";
import ChinaLibrary2Img from "../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-02.png";
import ChinaLibrary3Img from "../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-03.png";
import ChinaLibrary4Img from "../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-04.png";
/////////////////////////////////종근당 HistoryMuseum//////////////////////////////////////////////////////////
import HistoryMuseumThum from "../../Data/WorksData/ProjectData/HistoryMuseum/Img/thumnailimg.png";
import HistoryMuseum1Img from "../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-01.png";
import HistoryMuseum2Img from "../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-02.png";
import HistoryMuseum3Img from "../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-03.png";
/////////////////////////////////카이스트 커뮤니티 라운지//////////////////////////////////////////////////////////
import KistThum from "../../Data/WorksData/ProjectData/Kist/Img/thumnailimg.png";
import Kist1Img from "../../Data/WorksData/ProjectData/Kist/Img/mainimg-01.png";
import Kist2Img from "../../Data/WorksData/ProjectData/Kist/Img/mainimg-02.png";
import Kist3Img from "../../Data/WorksData/ProjectData/Kist/Img/mainimg-03.png";
import Kist4Img from "../../Data/WorksData/ProjectData/Kist/Img/mainimg-04.png";
/////////////////////////////////동대문 플라자//////////////////////////////////////////////////////////
import DDPThum from "../../Data/WorksData/ProjectData/DDP/Img/thumnailimg.png";
import DDP1Img from "../../Data/WorksData/ProjectData/DDP/Img/mainimg-01.png";
import DDP2Img from "../../Data/WorksData/ProjectData/DDP/Img/mainimg-02.png";
import DDP3Img from "../../Data/WorksData/ProjectData/DDP/Img/mainimg-03.png";
/////////////////////////////////롯데월드 아쿠아리움//////////////////////////////////////////////////////////
import AquariumThum from "../../Data/WorksData/ProjectData/Aquarium/Img/thumnailimg.png";
import Aquarium1Img from "../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-01.png";
import Aquarium2Img from "../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-02.png";
import Aquarium3Img from "../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-03.png";
import Aquarium4Img from "../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-04.png";
import Aquarium5Img from "../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-05.png";
/////////////////////////////////KimWall//////////////////////////////////////////////////////////
import KimChungUpWallThum from "../../Data/WorksData/ProjectData/KimChungUpWall/Img/thumnailimg.png";
import KimChungUpWall1Img from "../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-01.png";
import KimChungUpWall2Img from "../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-02.png";
import KimChungUpWall3Img from "../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-03.png";
import KimChungUpWall4Img from "../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-04.png";
/////////////////////////////////롯데월드 아쿠아리움//////////////////////////////////////////////////////////
import ComedyTownThum from "../../Data/WorksData/ProjectData/ComedyTown/Img/thumnailimg.png";
import ComedyTown1Img from "../../Data/WorksData/ProjectData/ComedyTown/Img/mainimg-01.png";
import ComedyTown2Img from "../../Data/WorksData/ProjectData/ComedyTown/Img/mainimg-02.png";

///////////////////////////////모션트렌드////////////////////////////////////////////
import MotionTrendThum from "../../Data/WorksData/ProjectData/MotionTrend/Img/thumnailimg.png";
import MotionTrend1Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-01.png";
import MotionTrend2Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-02.png";
import MotionTrend3Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-03.png";
import MotionTrend4Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-04.png";
import MotionTrend5Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-05.png";
import MotionTrend6Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-06.png";
import MotionTrend7Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-07.png";
import MotionTrend8Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-08.png";
import MotionTrend9Img from "../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-09.png";

///////////////////////////////프롱////////////////////////////////////////////
import ProngThum from "../../Data/WorksData/ProjectData/Prong/Img/thumnailimg.png";
import Prong1Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-01.png";
import Prong2Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-02.png";
import Prong3Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-03.png";
import Prong4Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-04.png";
import Prong5Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-05.png";
import Prong6Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-06.png";
import Prong7Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-07.png";
import Prong8Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-08.png";
import Prong9Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-09.png";
import Prong10Img from "../../Data/WorksData/ProjectData/Prong/Img/mainimg-10.png";

///////////////////////////////레논////////////////////////////////////////////
import LennonThum from "../../Data/WorksData/ProjectData/Lennon/Img/thumnailimg.png";
import Lennon1Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-01.png";
import Lennon2Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-02.png";
import Lennon3Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-03.png";
import Lennon4Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-04.png";
import Lennon5Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-05.png";
import Lennon6Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-06.png";
import Lennon7Img from "../../Data/WorksData/ProjectData/Lennon/Img/mainimg-07.png";

///////////////////////////////더널리////////////////////////////////////////////
import TheNulliThum from "../../Data/WorksData/ProjectData/TheNulli/Img/thumnailimg.png";
import TheNulli1Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-01.png";
import TheNulli2Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-02.png";
import TheNulli3Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-03.png";
import TheNulli4Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-04.png";
import TheNulli5Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-05.png";
import TheNulli6Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-06.png";
import TheNulli7Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-07.png";
import TheNulli8Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-08.png";
import TheNulli9Img from "../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-09.png";

///////////////////////////////세종시////////////////////////////////////////////
import SejongSiThum from "../../Data/WorksData/ProjectData/SejongSi/Img/thumnailimg.png";
import SejongSi1Img from "../../Data/WorksData/ProjectData/SejongSi/Img/mainimg-01.png";

///////////////////////////////토스트캔버스////////////////////////////////////////////
import ToastCanvasThum from "../../Data/WorksData/ProjectData/ToastCanvas/Img/thumnailimg.png";
import ToastCanvas1Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-01.png";
import ToastCanvas2Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-02.png";
import ToastCanvas3Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-03.png";
import ToastCanvas4Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-04.png";
import ToastCanvas5Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-05.png";
import ToastCanvas6Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-06.png";
import ToastCanvas7Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-07.png";
import ToastCanvas8Img from "../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-08.png";

///////////////////////////////엑스온////////////////////////////////////////////
import ExonThum from "../../Data/WorksData/ProjectData/Exon/Img/thumnailimg.png";
import Exon1Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-01.png";
import Exon2Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-02.png";
import Exon3Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-03.png";
import Exon4Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-04.png";
import Exon5Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-05.png";
import Exon6Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-06.png";
import Exon7Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-07.png";
import Exon8Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-08.png";
import Exon9Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-09.png";
import Exon10Img from "../../Data/WorksData/ProjectData/Exon/Img/mainimg-10.png";

///////////////////////////////한양도성////////////////////////////////////////////
import HanyangARThum from "../../Data/WorksData/ProjectData/HanyangAR/Img/thumnailimg.png";
import HanyangAR1Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-01.png";
import HanyangAR2Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-02.png";
import HanyangAR3Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-03.png";
import HanyangAR4Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-04.png";
import HanyangAR5Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-05.png";
import HanyangAR6Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-06.png";
import HanyangAR7Img from "../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-07.png";

///////////////////////////////SamsungPMS////////////////////////////////////////////
import SamsungPMSThum from "../../Data/WorksData/ProjectData/SamsungPMS/Img/thumnailimg.png";
import SamsungPMS1Img from "../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-01.png";
// import SamsungPMS2Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-02.png';
// import SamsungPMS3Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-03.png';
// import SamsungPMS4Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-04.png';
// import SamsungPMS5Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-05.png';
// import SamsungPMS6Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-06.png';
// import SamsungPMS7Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-07.png';
// import SamsungPMS8Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-08.png';
// import SamsungPMS9Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-09.png';

///////////////////////////////Kotra////////////////////////////////////////////
import KotraThum from "../../Data/WorksData/ProjectData/Kotra/Img/thumnailimg.png";
import Kotra1Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-01.png";
import Kotra2Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-02.png";
import Kotra3Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-03.png";
import Kotra4Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-04.png";
import Kotra5Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-05.png";
import Kotra6Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-06.png";
import Kotra7Img from "../../Data/WorksData/ProjectData/Kotra/Img/mainimg-07.png";

///////////////////////////////Famoz////////////////////////////////////////////
import FamozThum from "../../Data/WorksData/ProjectData/Famoz/Img/thumnailimg.png";
import Famoz1Img from "../../Data/WorksData/ProjectData/Famoz/Img/mainimg-01.png";

///////////////////////////////VideoMonster////////////////////////////////////////////
import VideoMonsterThum from "../../Data/WorksData/ProjectData/VideoMonster/Img/thumnailimg.png";
import VideoMonster1Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-01.png";
import VideoMonster2Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-02.png";
import VideoMonster3Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-03.png";
import VideoMonster4Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-04.png";
import VideoMonster5Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-05.png";
import VideoMonster6Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-06.png";
import VideoMonster7Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-07.png";
import VideoMonster8Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-08.png";

///Icarus
import IcarusImg from "../../Data/WorksData/ProjectData/Icarus/210427_1차완성_Ash.jpg";
import IcarusThum from "../../Data/WorksData/ProjectData/Icarus/Icarus.jpeg";

//Yame
import YameThum from "../../Data/WorksData/ProjectData/Yame/thumb_Yame_503x503.jpg";

//FarmPharam
import FarmPharamThum from "../../Data/WorksData/ProjectData/FarmPharm/thumb_Farm_503x503.jpg";

//살으리
import SareuriThum from "../../Data/WorksData/ProjectData/ㅅㅇㄹ/thumb_ㅅㅇㄹ_503x503.jpg";

//Guard
import GuardThum from "../../Data/WorksData/ProjectData/GuardSignature/thumb_GuardSignature_503x503.jpg";

//WhatHappen
import WhatHappenThum from "../../Data/WorksData/ProjectData/WHT30/thumb_WHT30_503x503.jpg";

//공작도시
import ArtificialcityThum from "../../Data/WorksData/ProjectData/Artificialcity/thumb_artificialcity_503x503.jpg";
import ArtificialcityImg from "../../Data/WorksData/ProjectData/Artificialcity/Contents/PG_ArtificialCity.jpg";

//videoOnly
import VideoOnlyThum from "../../Data/WorksData/ProjectData/Videonly/thumb_videonly_503x503.jpg";

//Nomad
import NomadThum from "../../Data/WorksData/ProjectData/NOMAD/thumb_nomad_503x503.jpg";
//UIX
import UIXThum from "../../Data/WorksData/ProjectData/UIX/thumb_uix_503x503.jpg";
//KKAL
import KKALThum from "../../Data/WorksData/ProjectData/KKAL/thumb_KKAL_503x503.jpg";
//Sejong
import SejongThum from "../../Data/WorksData/ProjectData/Sejong/thumb_sejong_503x503.jpg";
import SejongImg from "../../Data/WorksData/ProjectData/Sejong/Contents/220216_sejong_projectguide.jpg";

import MethinThum from "../../Data/WorksData/ProjectData/Methin/thumb_methin_503x503.jpg";

import ServerKitThum from "../../Data/WorksData/ProjectData/Servekit/thumb_severkit_503x503.jpg";

import ConceptCubeThum from "../../Data/WorksData/ProjectData/ConceptCube/thumb_conceptcube_503x503.jpg";

import LocaMoneyThum from "../../Data/WorksData/ProjectData/LocaMoney/thumb_loca_503x503.jpg";
import LocaMoneyImg from "../../Data/WorksData/ProjectData/LocaMoney/Contents/220214_locacard.jpg";

import MaxiusThum from "../../Data/WorksData/ProjectData/Maxius/thumb_maxius_503x503.jpg";
// import NomadThum from "../../Data/WorksData/ProjectData/NOMAD/thumb_nomad_503x503.jpg";
// import VideoMonster8Img from "../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-08.png";
import { CATEGORIES } from "../../../Modules/Contant";
// import  {All} from "../../../Modules/funtions"
//Exon, SamsungPMS, SejongSi, MotionTrend, Kotra, VideoMonster, ToastCanvas, HanyangAR, Prong, Lennon, Famoz, TheNulli

export default {
    list: [
        {
            id: "Maxius",
            category: CATEGORIES.CIBI,
            img: MaxiusThum,
            video: null,
            imges: [
                ...Array(38)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/Maxius/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "Maxius",
            sub: "Homepage Development",
            name: "Maxius",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "LocaMoney",
            category: CATEGORIES.Ad,
            img: LocaMoneyThum,
            video: null,
            imges: [LocaMoneyImg],
            title: "LocaMoney",
            sub: "Homepage Development",
            name: "LocaMoney",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ConceptCube",
            category: CATEGORIES.CIBI,
            img: ConceptCubeThum,
            video: null,
            imges: [
                ...Array(36)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/ConceptCube/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "ConceptCube",
            sub: "Homepage Development",
            name: "ConceptCube",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ServerKit",
            category: CATEGORIES.CIBI,
            img: ServerKitThum,
            video: null,
            imges: [
                ...Array(40)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/Servekit/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "ServerKit",
            sub: "Homepage Development",
            name: "ServerKit",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Methin",
            category: CATEGORIES.Cafe,
            img: MethinThum,
            video: null,
            imges: [
                ...Array(58)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/Methin/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "Sejong",
            sub: "Homepage Development",
            name: "Sejong",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Sejong",
            category: CATEGORIES.Ad,
            img: SejongThum,
            video: null,
            imges: [SejongImg],
            title: "Sejong",
            sub: "Homepage Development",
            name: "Sejong",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "KKAL",
            category: CATEGORIES.CIBI,
            img: KKALThum,
            video: null,
            imges: [
                ...Array(36)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/KKAL/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "KKAL",
            sub: "Homepage Development",
            name: "KKAL",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "UIX",
            category: CATEGORIES.CIBI,
            img: UIXThum,
            video: null,
            imges: [
                ...Array(20)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/UIX/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "NOMAD",
            sub: "Homepage Development",
            name: "NOMAD",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "NOMAD",
            category: CATEGORIES.CIBI,
            img: NomadThum,
            video: null,
            imges: [
                ...Array(34)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/NOMAD/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "NOMAD",
            sub: "Homepage Development",
            name: "NOMAD",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "VideoOnly",
            category: CATEGORIES.CIBI,
            img: VideoOnlyThum,
            video: null,
            imges: [
                ...Array(44)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/Videonly/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "VideoOnly",
            sub: "Homepage Development",
            name: "VideoOnly",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Artificialcity",
            category: CATEGORIES.Ad,
            img: ArtificialcityThum,
            video: null,
            imges: [ArtificialcityImg],
            title: "Artificialcity",
            sub: "Homepage Development",
            name: "Artificialcity",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "WhatHappen",
            category: CATEGORIES.CIBI,
            img: WhatHappenThum,
            video: null,
            imges: [
                ...Array(72)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/WHT30/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "WhatHappen",
            sub: "Homepage Development",
            name: "WhatHappen",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "GuardSignature",
            category: CATEGORIES.CIBI,
            img: GuardThum,
            video: null,
            imges: [
                ...Array(36)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/GuardSignature/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "GuardSignature",
            sub: "Homepage Development",
            name: "GuardSignature",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ㅅㅇㄹ",
            category: CATEGORIES.CIBI,
            img: SareuriThum,
            video: null,
            imges: [
                ...Array(23)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/ㅅㅇㄹ/Contents/image (${index + 1}).jpg`);
                    }),
            ],
            title: "ㅅㅇㄹ",
            sub: "Homepage Development",
            name: "ㅅㅇㄹ",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "FarmPharam",
            category: CATEGORIES.Cafe,
            img: FarmPharamThum,
            video: null,
            imges: [
                ...Array(23)
                    .fill()
                    .map((item, index) => {
                        return require(`../../Data/WorksData/ProjectData/FarmPharm/Contents/farm_pharm_${index + 1}.jpg`);
                    }),
            ],
            title: "FarmPharam",
            sub: "Homepage Development",
            name: "FarmPharam",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Yame",
            category: CATEGORIES.CIBI,
            img: YameThum,
            video: null,
            imges: [SamsungPMS1Img],
            title: "Yame",
            sub: "Homepage Development",
            name: "Yame",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Icarus",
            category: CATEGORIES.Ad,
            img: IcarusThum,
            video: null,
            imges: [IcarusImg],
            title: "Icarus",
            sub: "Homepage Development",
            name: "Icarus",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },

        //추가//
        {
            id: "MotionTrend",
            category: CATEGORIES.Dev,
            img: MotionTrendThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [MotionTrend1Img, MotionTrend2Img, MotionTrend3Img, MotionTrend4Img, MotionTrend5Img, MotionTrend6Img, MotionTrend7Img, MotionTrend8Img, MotionTrend9Img],
            title: "MotionTrend",
            sub: "Homepage Development",
            name: "MotionTrend",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Prong",
            category: CATEGORIES.Cafe,
            img: ProngThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [Prong1Img, Prong2Img, Prong3Img, Prong4Img, Prong5Img, Prong6Img, Prong7Img, Prong8Img, Prong9Img, Prong10Img],
            title: "Prong",
            sub: "Homepage Development",
            name: "Prong",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Lennon",
            category: CATEGORIES.Dev,
            img: LennonThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [Lennon1Img, Lennon2Img, Lennon3Img, Lennon4Img, Lennon5Img, Lennon6Img, Lennon7Img],
            title: "Lennon",
            sub: "Homepage Development",
            name: "Lennon",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "TheNulli",
            category: CATEGORIES.Dev,
            img: TheNulliThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [TheNulli1Img, TheNulli2Img, TheNulli3Img, TheNulli4Img, TheNulli5Img, TheNulli6Img, TheNulli7Img, TheNulli8Img, TheNulli9Img],
            title: "TheNulli",
            sub: "Homepage Development",
            name: "TheNulli",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "SejongSi",
            category: CATEGORIES.Dev,
            img: SejongSiThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [SejongSi1Img],
            title: "SejongSi",
            sub: "Homepage Development",
            name: "SejongSi",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ToastCanvas",
            category: CATEGORIES.Dev,
            img: ToastCanvasThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [ToastCanvas1Img, ToastCanvas2Img, ToastCanvas3Img, ToastCanvas4Img, ToastCanvas5Img, ToastCanvas6Img, ToastCanvas7Img, ToastCanvas8Img],
            title: "ToastCanvas",
            sub: "Homepage Development",
            name: "ToastCanvas",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Exon",
            category: CATEGORIES.Dev,
            img: ExonThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [Exon1Img, Exon2Img, Exon3Img, Exon4Img, Exon5Img, Exon6Img, Exon7Img, Exon8Img, Exon9Img, Exon10Img],
            title: "Exon",
            sub: "Homepage Development",
            name: "Exon",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "HanyangAR",
            category: CATEGORIES.IT,
            img: HanyangARThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [HanyangAR1Img, HanyangAR2Img, HanyangAR3Img, HanyangAR4Img, HanyangAR5Img, HanyangAR6Img, HanyangAR7Img],
            title: "HanyangAR",
            sub: "Homepage Development",
            name: "HanyangAR",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "SamsungPMS",
            category: CATEGORIES.Dev,
            img: SamsungPMSThum,
            video: null,
            imges: [SamsungPMS1Img],
            title: "SamsungPMS",
            sub: "Kiosk & Android Development",
            name: "SamsungPMS",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Kotra",
            category: CATEGORIES.IT,
            img: KotraThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [Kotra1Img, Kotra2Img, Kotra3Img, Kotra4Img, Kotra5Img, Kotra6Img, Kotra7Img],
            title: "Kotra",
            sub: "Homepage Development",
            name: "Kotra",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Famoz",
            category: CATEGORIES.Dev,
            img: FamozThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [Famoz1Img],
            title: "Famoz",
            sub: "Homepage Development",
            name: "Famoz",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "VideoMonster",
            category: CATEGORIES.Dev,
            img: VideoMonsterThum,
            // vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [VideoMonster1Img, VideoMonster2Img, VideoMonster3Img, VideoMonster4Img, VideoMonster5Img, VideoMonster6Img, VideoMonster7Img, VideoMonster8Img],
            title: "VideoMonster",
            sub: "Homepage Development",
            name: "VideoMonster",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },

        //추가//
        {
            id: "KimMuseum",
            category: CATEGORIES.Media,
            img: KimChungUpMuseumThum,
            vimeo: "https://player.vimeo.com/video/151469589",
            video: null,
            imges: [KimChungUpMuseum1Img, KimChungUpMuseum2Img, KimChungUpMuseum3Img],
            title: "KimChungUpMuseum",
            sub: "Homepage Development",
            name: "KimChungUpMuseum",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Jurassic",
            category: CATEGORIES.Media,
            img: JurassicThum,
            vimeo: "https://player.vimeo.com/video/196838613",
            video: null,
            imges: [Jurassic1Img, Jurassic2Img],
            title: "Anmyeondo Jurassic Museum",
            sub: "Homepage Development",
            name: "쥬라기",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Galleria",
            category: CATEGORIES.Media,
            img: GalleriaThum,
            vimeo: "https://player.vimeo.com/video/151468979",
            video: null,
            imges: [Galleria1Img, Galleria2Img, Galleria3Img, Galleria4Img, Galleria5Img, Galleria6Img],
            title: "Gangnam Galleria",
            sub: "Homepage Development",
            name: "Gangnam Galleria",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "RailPark",
            category: CATEGORIES.Media,
            img: RailParkThum,
            vimeo: "https://player.vimeo.com/video/244931138",
            video: null,
            imges: [RailPark1Img, RailPark2Img, RailPark3Img, RailPark5Img, RailPark6Img, RailPark8Img],
            title: "Uiwang-RailPark",
            sub: "Homepage Development",
            name: "UiwangRailPark",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "SumsungSds",
            category: CATEGORIES.Media,
            img: SamsungSdsThum,
            vimeo: "https://player.vimeo.com/video/151345247",
            video: null,
            imges: [SamsungSds1Img, SamsungSds2Img, SamsungSds3Img, SamsungSds4Img, SamsungSds5Img, SamsungSds6Img, SamsungSds7Img, SamsungSds8Img, SamsungSds9Img, SamsungSds10Img, SamsungSds11Img],
            title: "SumsungSDS",
            sub: "Homepage Development",
            name: "SumsungSDS",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "SportsMonster",
            category: CATEGORIES.Media,
            img: SportsMonsterThum,
            vimeo: "https://player.vimeo.com/video/237692139",
            video: null,
            imges: [SportsMonster1Img, SportsMonster2Img, SportsMonster3Img, SportsMonster9Img, SportsMonster10Img, SportsMonster12Img, SportsMonster14Img],
            title: "SportsMonster",
            sub: "Homepage Development",
            name: "SportsMonster",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "LGDisplay",
            category: CATEGORIES.Media,
            img: LGDisplayThum,
            vimeo: "https://player.vimeo.com/video/244596501",
            video: null,
            imges: [LGDisplay1Img, LGDisplay2Img, LGDisplay4Img, LGDisplay7Img, LGDisplay11Img],
            title: "LGDisplay",
            sub: "Homepage Development",
            name: "LGDisplay",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "SamsungSds2",
            category: CATEGORIES.Media,
            img: SamsungSds2Thum,
            vimeo: "https://player.vimeo.com/video/187940773",
            video: null,
            imges: [SamsungSds21Img, SamsungSds22Img, SamsungSds23Img],
            title: "Samsung SDS 3WALL CINERMA",
            sub: "Homepage Development",
            name: "Samsung SDS 3WALL CINERMA",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },

        {
            id: "SkyPark",
            category: CATEGORIES.Media,
            img: SkyParkThum,
            vimeo: "https://player.vimeo.com/video/245166605",
            video: null,
            imges: [SkyPark1Img, SkyPark2Img, SkyPark3Img, SkyPark5Img, SkyPark6Img, SkyPark7Img, SkyPark9Img, SkyPark10Img, SkyPark13Img, SkyPark27Img],
            title: "Taean Shooting Star Sky Park",
            sub: "Homepage Development",
            name: "Taean Shooting Star Sky Park",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ChinaLibrary",
            category: CATEGORIES.Media,
            img: ChinaLibraryThum,
            vimeo: "https://player.vimeo.com/video/151366504",
            video: null,
            imges: [ChinaLibrary1Img, ChinaLibrary2Img, ChinaLibrary3Img, ChinaLibrary4Img],
            title: "Zhengzhou Library",
            sub: "Homepage Development",
            name: "Zhengzhou Library",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "HistoryMuseum",
            category: CATEGORIES.Media,
            img: HistoryMuseumThum,
            vimeo: "https://player.vimeo.com/video/151469236",
            video: null,
            imges: [HistoryMuseum1Img, HistoryMuseum2Img, HistoryMuseum3Img],
            title: "Chong Kun Dang",
            sub: "Homepage Development",
            name: "Chong Kun Dang",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Kist",
            category: CATEGORIES.Media,
            img: KistThum,
            vimeo: "https://player.vimeo.com/video/151470243",
            video: null,
            imges: [Kist1Img, Kist2Img, Kist3Img, Kist4Img],
            title: "KAIST Community Lounge",
            sub: "Homepage Development",
            name: "KAIST Community Lounge",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "DDP",
            category: CATEGORIES.Media,
            img: DDPThum,
            vimeo: "https://player.vimeo.com/video/151467892",
            video: null,
            imges: [DDP1Img, DDP2Img, DDP3Img],
            title: "Dongdaemun Digital Plaza",
            sub: "Homepage Development",
            name: "Dongdaemun Digital Plaza",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "Aquarium",
            category: CATEGORIES.Media,
            img: AquariumThum,
            vimeo: "https://player.vimeo.com/video/151466362",
            video: null,
            imges: [Aquarium1Img, Aquarium2Img, Aquarium3Img, Aquarium4Img, Aquarium5Img],
            title: "Lotte World Aquarium",
            sub: "Homepage Development",
            name: "Lotte World Aquarium",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "KimWall",
            category: CATEGORIES.Media,
            img: KimChungUpWallThum,
            vimeo: "https://player.vimeo.com/video/151469589",
            video: null,
            imges: [KimChungUpWall1Img, KimChungUpWall2Img, KimChungUpWall3Img, KimChungUpWall4Img],
            title: "KimChungUpMuseum Wall Library",
            sub: "Homepage Development",
            name: "KimChungUpMuseum Wall Library",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        {
            id: "ComedyTown",
            category: CATEGORIES.Media,
            img: ComedyTownThum,
            vimeo: "https://player.vimeo.com/video/196838939",
            video: null,
            imges: [ComedyTown1Img, ComedyTown2Img],
            title: "Cheongdo Comedy Town",
            sub: "Homepage Development",
            name: "Cheongdo Comedy Town",
            text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        },
        // {
        // 	id: "BetterSamsung",
        // 	category: 1,
        // 	img: BetterSamsung,
        // 	fullScreenImg: fullScreenBetterSamsung,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Better Samsung",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
        // },
        // {
        // 	id: "Happened",
        // 	category: 3,
        // 	img: Happened,
        // 	fullScreenImg: fullScreenHappened,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "What Happened to 30's",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "Hyundai_rima",
        // 	category: 2,
        // 	img: Hyundai_rima,
        // 	fullScreenImg: fullScreenHyundaiRima,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Hyundai Card - Rima",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "MilkShake",
        // 	category: 1,
        // 	img: MilkShake,
        // 	fullScreenImg: fullScreenMilkShake,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Milkshake M/V",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."

        // },
        // {
        // 	id: "Priest",
        // 	category: 1,
        // 	img: Priest,
        // 	fullScreenImg: fullScreenPriest,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Priest",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "Circle",
        // 	category: 2,
        // 	img: Circle,
        // 	fullScreenImg: fullScreenCircle,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Circle",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "Skylife",
        // 	category: 3,
        // 	img: Skylife,
        // 	fullScreenImg: fullScreenSkylife,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Skylife",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // // {
        // // 	id: "Daisy",
        // // 	category: 7,
        // // 	img: Daisy,
        // // 	fullScreenImg: ProjectData.Daisy
        // // },
        // {
        // 	id: "Timing",
        // 	category: 2,
        // 	img: Timing,
        // 	fullScreenImg: fullScreenTiming,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Timing Alice",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "TimingDark",
        // 	category: 2,
        // 	img: TimingDark,
        // 	fullScreenImg: fullScreenTimingDark,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Timing Alice",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "Lyn",
        // 	category: 1,
        // 	img: Lyn,
        // 	fullScreenImg: fullScreenLyn,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Lyn - the Lightbringer",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // {
        // 	id: "Victor",
        // 	category: 1,
        // 	img: Victor,
        // 	fullScreenImg: fullScreenVictor,
        // 	title: "Provider",
        // 	sub: "Homepage renewal",
        // 	name: "Victor - for Elise",
        // 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
        // },
        // // {
        // // 	id: "tv29",
        // // 	category: 3,
        // // 	img: tv29,
        // // 	fullScreenImg: ProjectData.tv29
        // // },
    ],
};
