import React, { Component } from "react";
import "./Style/TenPage.scss";
import jp from "../../_asset/images/grvina/10-graph-01@3x.png";
import kr from "../../_asset/images/grvina/10-graph-02@3x.png";
import us from "../../_asset/images/grvina/10-graph-03@3x.png";
import etc from "../../_asset/images/grvina/10-graph-04@3x.png";

class TenPage extends Component {
  state = {
    isMobile: window.innerWidth <= 768,
  };
  handleResize = () => {
    // Update isMobile state based on the current window width
    this.setState({ isMobile: window.innerWidth <= 768 }); // You can adjust the threshold as needed
  };

  componentDidMount() {
    // Initial check on component mount
    this.handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener("resize", this.handleResize);
  }
  render() {
    const { isMobile } = this.state;
    return (
      <div className="TenPageGrvina">
        <div className="TenPageGrvina__title">Introduce</div>
        <div className="TenPageGrvina__content">
          <div className="TenPageGrvina__left">
            <div className="TenPageGrvina__item">
              <div className="TenPageGrvina__heading">연혁</div>
              <div className="TenPageGrvina__dash1"></div>
            </div>

            {isMobile ? (
              <div className="TenPageGrvina__desc">
                <div className="TenPageGrvina__text1 text-mobile1">
                  1996년 7월 100% 투자{" "}
                  <span className="TenPageGrvina__strong">
                    베트남 생산공장 설립
                  </span>
                </div>
                <div className="TenPageGrvina__text1 text-mobile2">
                  한국에서의 일본 양말 수출의{" "}
                  <span className="TenPageGrvina__strong">1위 업체 </span>다년간
                  고수
                </div>
                <div className="TenPageGrvina__text1 text-mobile3">
                  2005년부터{" "}
                  <span className="TenPageGrvina__strong">
                    미국 수출을 개시
                  </span>
                  하여 수출대상 국을 안정화
                </div>
                <div className="TenPageGrvina__text2">
                  베트남 양말공장 설립 선구자적 입지이며, 베트남 공장 3회에 걸쳐
                  확장
                </div>
              </div>
            ) : (
              <div className="TenPageGrvina__desc">
                <div className="TenPageGrvina__text1 text-mobile1">
                  1996년 7월 100% 투자 베트남{" "}
                  <span className="TenPageGrvina__strong">생산공장 설립</span>
                </div>
                <div className="TenPageGrvina__text1 text-mobile2">
                  한국에서의 일본 양말 수출의{" "}
                  <span className="TenPageGrvina__strong">1위 업체 </span>다년간
                  고수
                </div>
                <div className="TenPageGrvina__text1 text-mobile3">
                  2005년부터 미국 수출을{" "}
                  <span className="TenPageGrvina__strong">
                    개시하여 수출대상
                  </span>
                  국을 안정화
                </div>
                <div className="TenPageGrvina__text2">
                  베트남 양말공장 설립 선구자적 입지이며, 베트남 공장 3회에 걸쳐
                  확장
                </div>
              </div>
            )}

            <div className="TenPageGrvina__item">
              <div className="TenPageGrvina__heading">
                <div className="TenPageGrvina__heading1">대표이사</div>
                <div className="TenPageGrvina__heading2">방대규</div>
              </div>
              <div className="TenPageGrvina__dash2"></div>
            </div>

            <div className="TenPageGrvina__desc">
              <div className="TenPageGrvina__text1">BYC 근무</div>
              <div className="TenPageGrvina__text1">Bottom Line근무</div>
              <div className="TenPageGrvina__text1">경덕상사 근무</div>
              <div className="TenPageGrvina__text1">
                GUO RYONG INDUSTRIAL 설립
              </div>
            </div>
          </div>
          <div className="TenPageGrvina__right">
            <img alt="" src={jp} className="TenPageGrvina__jp" />
            <img alt="" src={kr} className="TenPageGrvina__kr" />
            <img alt="" src={us} className="TenPageGrvina__us" />
            <img alt="" src={etc} className="TenPageGrvina__etc" />
          </div>
        </div>
      </div>
    );
  }
}

export default TenPage;
