import React from "react";
import awardimg from "../../../../_asset/images/Kaola/img_web_award.png";

const Kaward = () => {
  return (
    <div className="Kawrd_container">
      <img src={awardimg} alt="award_image" style={{ width: "100%" }} />
    </div>
  );
};

export default Kaward;
