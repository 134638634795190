import React, { Component } from "react";
import "./News2.scss";
import img01 from "./Image/001.png";
import img02 from "./Image/002.png";
import img03 from "./Image/003.png";

class News1 extends Component {
  render() {
    return (
      <div className="News1Container">
        <div className="New1Main">
          <div className="News1TitleBox">
            <div className="News1RightBox">
              <div className="News1Title">TDC Member of The Month</div>
              <div className="News1Sub">
                An interview between Elizabeth Carey Smith and Kris Sowersby for
                the TDC Member of The Month series.
              </div>
            </div>
            <div className="News1LeftBox">
              <div className="News1Sort">Interviews</div>
            </div>
          </div>
          <div className="ImgBox">
            <img className="Img01" src={img01} />
            <img className="Img02" src={img02} />
            <img className="Img03" src={img03} />
          </div>
        </div>
      </div>
    );
  }
}

export default News1;
