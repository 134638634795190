import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import "./Menu.scss";
import HeaderPop from "../Header/HeaderPop.js";
import download from "../../_asset/images/common/download-big-icon.svg";
import ReadyPopup from "../../Common/PopUp/ReadyPopup";
import checkDownload from "../../_asset/images/mainback/download-big-icon.svg";
const LEFT_ITEM = [
  {
    src: "/works",
    label: "experience",
  },
  {
    src: "/about",
    label: "about",
  },
  {
    src: "/contact",
    label: "contact",
  },
];
class Menu2 extends Component {
  state = {
    isDialogOpen: false,
    isOneDialogOpen: false,
    index: 1,
  };
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      isOneDialogOpen: false,
    });
  };
  buttonClick = (index) => {
    this.setState(
      {
        index,
      },
      () => this.setState({ isDialogOpen: true })
    );
  };
  renderLeftText = () => {
    return LEFT_ITEM.map((item) => {
      return (
        <div className="MenuLeftItem" key={item.label}>
          <a href={item.src}>
            <span className="MenuLeftText">
              <span className="mask-text" style={{ animationDelay: "0ms" }}>
                <span>{item.label}</span>
              </span>
            </span>
          </a>
        </div>
      );
    });
  };
  render() {
    const { onClose, open, noButton, dialogType, onButtonClick } = this.props;
    return (
      <Dialog fullWidth={this.props.fullWidth} maxWidth={"lg"} open={open}>
        <div className="MenuViewMain show">
          <div className="MenuViewDimd">
            <HeaderPop onClose={onClose}></HeaderPop>
          </div>
          <div className="MenuBox">
            <div className="MenuLeftBox">
              <div className="MenuLeftTitle">
                {this.renderLeftText()}
                {/* <div className='MenuLeftItem'><a href="/Reauest"><span className="MenuLeftText"><span className="mask-text" style={{animationDelay: "0ms"}}><span>Request</span></span></span></a></div> */}
                {/* <div className="MenuLeftItem Notice">
                  <a href="/notice">
                    <span className="MenuLeftText">
                      <span
                        className="mask-text"
                        style={{ animationDelay: "0ms" }}
                      >
                        <span>notice</span>
                      </span>
                    </span>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="MenuRightBox">
              <div className="MenuRightList a">
                <div className="MenuRightTitle">Information</div>
                <div className="MenuRightSubList">
                  <div className="MenuRightText">
                    <a href="/about">About</a>
                  </div>
                  <div className="MenuRightText">
                    <a href="/contact">Contact</a>
                  </div>
                  {/* <div className='MenuRightText'><a href='/notice'>Notice</a></div> */}
                  {/* <div onClick={() => this.buttonClick()} className='MenuRightText'><a>Introduction<img className="MenuImg" src={download} alt="" /></a></div>
                                    <div onClick={() => this.buttonClick()} className='MenuRightText'><a>Brand Guide <img className="MenuImg" src={download} alt="" /></a></div> */}
                </div>
              </div>
              <div className="MenuRightList b">
                <div className="MenuRightTitle Sharing">
                  <a>Sharing</a>
                </div>

                <div className="MenuRightSubList Sharing">
                  <div className="MenuRightText Sharing">
                    <a>Font</a>
                  </div>
                  <div className="MenuRightText Sharing">
                    <a>Form</a>
                  </div>
                  <div className="MenuRightText Sharing">
                    <a>Logo</a>
                  </div>
                  <div className="MenuRightText Sharing">
                    <a>Design</a>
                  </div>
                </div>
              </div>
              <div className="MenuRightList c">
                <div className="MenuRightTitle">Follow</div>
                <div className="MenuRightSubList">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.buttonClick();
                    }}
                    className="MenuRightText"
                  >
                    <a>Behance</a>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.buttonClick();
                    }}
                    className="MenuRightText"
                  >
                    <a>Facebook</a>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.buttonClick();
                    }}
                    className="MenuRightText"
                  >
                    <a>Instagram</a>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.buttonClick();
                    }}
                    className="MenuRightText"
                  >
                    <a>Vimeo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReadyPopup
          open={this.state.isDialogOpen}
          onClickPopupNext={this.handleClickPopupNext}
          onClose={this.handleDialogClose}
          index={this.state.index}
        />
      </Dialog>
    );
  }
}

export default Menu2;
