import React from "react";
import "./style/PbgPageFour.scss";
import bg01 from "../../../../_asset/images/Prorenata/img_prn14.jpg";
import bg02 from "../../../../_asset/images/Prorenata/img_prn13.jpg";

const PbgPageFour = () => {
  return (
    <div className="PbgPageFour_container">
      <div className="PbgPage_cont_1">
        <div className="Left_cont">
          <img src={bg01} alt="bg_01" />
        </div>
      </div>
      <div className="PbgPage_cont_2">
        <div className="Right_cont">
          <img src={bg02} alt="bg_02" />
        </div>
      </div>
    </div>
  );
};

export default PbgPageFour;
