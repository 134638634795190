import React, { useState, useEffect } from "react";
import Gcover from "./function/Gcover";
import Goverview from "./function/Goverview";
import Gdesign from "./function/Gdesign";
import GprojectC from "./function/GprojectC";
import Gconstruction from "./function/Gconstruction";
import Gimage from "./function/Gimage";
import Gvaluable from "./function/Gvaluable";
import GconPremium from "./function/GconPremium";
import Gfooter from "./function/Gfooter";

const G9 = () => {
  const [pageY, setPageY] = useState(0);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "GodthamRoundedTTF";
    const handleScroll = () => {
      const { pageYOffset } = window;
      setPageY(pageYOffset);
      console.log(pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="C9_container">
      <Gcover></Gcover>
      <Goverview pageY={pageY}></Goverview>
      <Gdesign pageY={pageY}></Gdesign>
      <GprojectC pageY={pageY}></GprojectC>
      <Gconstruction pageY={pageY}></Gconstruction>
      <Gimage pageY={pageY}></Gimage>
      <Gvaluable pageY={pageY}></Gvaluable>
      <GconPremium pageY={pageY}></GconPremium>
      <Gfooter pageY={pageY}></Gfooter>
    </div>
  );
};

export default G9;
