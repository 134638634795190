import React, { Component } from "react";
import "./MainLinkBox.scss";
import First from "../PageList/FirstPage";
import Second from "../PageList/SecondPage";
import Third from "../PageList/ThirdPage";
import Forth from "../PageList/ForthPage";
import Fifth from "../PageList/FifthPage";
import ReactFullpage from "@fullpage/react-fullpage";
import Control from "../../Common/Control/Control";
import FooterNav from "../../Common/FooterNav/FooterNav";

class MainLinkBox extends Component {
  state = {
    currentPage: 1,
    isLogoBlack: false,
  };

  anchors = ["firstpage", "secondpage", "thirdpage", "fourpage", "fivepage"];

  fullpageapi = null;

  componentDidMount = () => {
    const history = this.props.history.location.hash;

    this.setState({ currentPage: this.getPageNum(history) });
  };

  getPageNum = (history) => {
    if (history === "#firstpage") {
      return 1;
    } else if (history === "#secondpage") {
      this.setState({ isLogoBlack: true });
      return 2;
    } else if (history === "#thirdpage") {
      return 3;
    } else if (history === "#fourpage") {
      this.setState({ isLogoBlack: true });
      return 4;
    } else if (history === "#fivepage") {
      return 5;
    } else {
      return 1;
    }
  };

  toNextPage = () => {
    if (this.state.currentPage < 5) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () =>
        this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage))
      );
    }
  };

  toPrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 }, () =>
        this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage))
      );
    }
  };

  renderPagePath = (num) => {
    if (num === 1) {
      return "firstpage";
    } else if (num === 2) {
      this.setState({ isLogoBlack: true });
      return "secondpage";
    } else if (num === 3) {
      return "thirdpage";
    } else if (num === 4) {
      this.setState({ isLogoBlack: true });
      return "fourpage";
    } else if (num === 5) {
      return "fivepage";
    }
  };

  render() {
    return (
      <>
        <ReactFullpage
          //fullpage options
          licenseKey={"YOUR_KEY_HERE"}
          scrollingSpeed={1000} /* Options here */
          anchors={this.anchors}
          onLeave={(e, E) => {
            this.setState({ currentPage: E.index + 1 });
            this.props.handleHeaderChange(E.index + 1);
          }}
          render={({ state, fullpageApi }) => {
            this.fullpageapi = fullpageApi;
            return (
              <ReactFullpage.Wrapper>
                <div
                  className="firstpage section"
                  style={{
                    // position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#FFF",
                    backgroundColor: "#1a1a19",
                  }}
                >
                  <First />
                </div>
                <div
                  className="secondpage section"
                  style={{
                    // position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#FFF",
                    backgroundColor: "#00dc16",
                  }}
                >
                  <Second />
                </div>
                <div
                  className="thirdpage section"
                  style={{
                    // position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#FFF",
                  }}
                >
                  <Third />
                </div>
                <div
                  className="fourpage section"
                  style={{
                    // position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#000",
                    backgroundColor: "#eaeaea",
                  }}
                >
                  <Forth />
                </div>
                <div
                  className="fivepage section"
                  style={{
                    // position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#FFF",
                  }}
                >
                  <Fifth />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
        <div className="FooterBox">
          <Control
            number={`0${this.state.currentPage}`}
            toNextPage={() => this.toNextPage()}
            toPrevPage={() => this.toPrevPage()}
          />
        </div>
      </>
    );
  }
}

export default MainLinkBox;

// animation-delay: 0ms
// animation-delay: 80ms
// animation-delay: 240ms;
// animation-delay: 320ms;
