import React, { Component } from 'react'
import './MainLinkBoxGrvina.scss'
import First from '../PageListGrvina/FirstPage'
import Second from '../PageListGrvina/SecondPage'
import Third from '../PageListGrvina/ThirdPage'
import Forth from '../PageListGrvina/ForthPage'
import Fifth from '../PageListGrvina/FifthPage.js'
import ReactFullpage from '@fullpage/react-fullpage'
import Control from '../../Common/Control/Control'
import FooterNav from '../../Common/FooterNav/FooterNav'
import SixPage from '../PageListGrvina/SixPage.js'
import SevenPage from '../PageListGrvina/SevenPage.js'
import EightPage from '../PageListGrvina/EightPage.js'
import NinePage from '../PageListGrvina/NinePage.js'
import TenPage from '../PageListGrvina/TenPage.js'
import ElevenPage from '../PageListGrvina/ElevenPage.js'

class MainLinkBoxGrvina extends Component {
  state = {
    currentPage: 1,
    isLogoBlack: false,
    isMobile: window.innerWidth <= 768,
  }

  anchors = [
    'firstpage',
    'secondpage',
    'thirdpage',
    'fourpage',
    'fivepage',
    'sixpage',
    'sevenpage',
    'eightpage',
    'ninepage',
    'tenpage',
    'elevenpage',
  ]

  fullpageapi = null

  componentDidMount = () => {
    const history = this.props.history.location.hash

    this.setState({ currentPage: this.getPageNum(history) })
  }

  getPageNum = (history) => {
    if (history === '#firstpage') {
      return 1
    } else if (history === '#secondpage') {
      this.setState({ isLogoBlack: true })
      return 2
    } else if (history === '#thirdpage') {
      return 3
    } else if (history === '#fourpage') {
      this.setState({ isLogoBlack: true })
      return 4
    } else if (history === '#fivepage') {
      return 5
    } else if (history === '#sixpage') {
      return 6
    } else if (history === '#sevenpage') {
      return 7
    } else if (history === '#eightpage') {
      return 8
    } else if (history === '#ninepage') {
      return 9
    } else if (history === '#tenpage') {
      return 10
    } else if (history === '#elevenpage') {
      return 11
    } else {
      return 1
    }
  }

  toNextPage = () => {
    if (this.state.currentPage < 5) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () =>
        this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage)),
      )
    }
  }

  toPrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 }, () =>
        this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage)),
      )
    }
  }

  renderPagePath = (num) => {
    if (num === 1) {
      return 'firstpage'
    } else if (num === 2) {
      this.setState({ isLogoBlack: true })
      return 'secondpage'
    } else if (num === 3) {
      return 'thirdpage'
    } else if (num === 4) {
      this.setState({ isLogoBlack: true })
      return 'fourpage'
    } else if (num === 5) {
      return 'fivepage'
    } else if (num === 6) {
      return 'fivepage'
    } else if (num === 7) {
      return 'sixpage'
    } else if (num === 8) {
      return 'eightpage'
    } else if (num === 9) {
      return 'ninepage'
    } else if (num === 10) {
      return 'tenpage'
    } else if (num === 11) {
      return 'elevenpage'
    }
  }
  handleResize = () => {
    // Update isMobile state based on the current window width
    this.setState({ isMobile: window.innerWidth <= 768 }) // You can adjust the threshold as needed
  }

  componentDidMount() {
    // Initial check on component mount
    this.handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { isMobile } = this.state
    if (isMobile) {
      // Render a mobile-friendly version without ReactFullpage
      return (
        <div style={{ wordBreak: 'keep-all' }}>
          <div
            className="firstpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
              backgroundColor: '#f4f4f4',
            }}
          >
            <First />
          </div>
          <div
            className="secondpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
              backgroundColor: '#00dc16',
            }}
          >
            <Second />
          </div>
          <div
            className="thirdpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
            }}
          >
            <Third />
          </div>
          <div
            className="fourpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#000',
              backgroundColor: '#eaeaea',
            }}
          >
            <Forth />
          </div>
          <div
            className="fivepage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
            }}
          >
            <Fifth />
          </div>
          <div
            className="fourpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#000',
              backgroundColor: '#eaeaea',
            }}
          >
            <SixPage />
          </div>
          <div
            className="secondpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
              backgroundColor: '#00dc16',
            }}
          >
            <SevenPage />
          </div>
          <div
            className="fourpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#000',
              backgroundColor: '#eaeaea',
            }}
          >
            <EightPage />
          </div>
          <div
            className="fivepage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
            }}
          >
            <NinePage />
          </div>
          <div
            className="fourpage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#000',
              backgroundColor: '#eaeaea',
            }}
          >
            <TenPage />
          </div>
          <div
            className="fivepage section"
            style={{
              // position: "fixed",
              width: '100%',
              height: '100%',
              color: '#FFF',
            }}
          >
            <ElevenPage />
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ wordBreak: 'keep-all' }}>
          <ReactFullpage
            //fullpage options
            licenseKey={'YOUR_KEY_HERE'}
            scrollingSpeed={1000} /* Options here */
            anchors={this.anchors}
            onLeave={(e, E) => {
              this.setState({ currentPage: E.index + 1 })
              this.props.handleHeaderChange(E.index + 1)
            }}
            render={({ state, fullpageApi }) => {
              this.fullpageapi = fullpageApi
              return (
                <ReactFullpage.Wrapper>
                  <div
                    className="firstpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                      backgroundColor: '#f4f4f4',
                    }}
                  >
                    <First />
                  </div>
                  <div
                    className="secondpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                      backgroundColor: '#00dc16',
                    }}
                  >
                    <Second />
                  </div>
                  <div
                    className="thirdpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                    }}
                  >
                    <Third />
                  </div>
                  <div
                    className="fourpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#000',
                      backgroundColor: '#eaeaea',
                    }}
                  >
                    <Forth />
                  </div>
                  <div
                    className="fivepage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                    }}
                  >
                    <Fifth />
                  </div>
                  <div
                    className="fourpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#000',
                      backgroundColor: '#eaeaea',
                    }}
                  >
                    <SixPage />
                  </div>
                  <div
                    className="secondpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                      backgroundColor: '#00dc16',
                    }}
                  >
                    <SevenPage />
                  </div>
                  <div
                    className="fourpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#000',
                      backgroundColor: '#eaeaea',
                    }}
                  >
                    <EightPage />
                  </div>
                  <div
                    className="fivepage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                    }}
                  >
                    <NinePage />
                  </div>
                  <div
                    className="fourpage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#000',
                      backgroundColor: '#eaeaea',
                    }}
                  >
                    <TenPage />
                  </div>
                  <div
                    className="fivepage section"
                    style={{
                      // position: "fixed",
                      width: '100%',
                      height: '100%',
                      color: '#FFF',
                    }}
                  >
                    <ElevenPage />
                  </div>
                </ReactFullpage.Wrapper>
              )
            }}
          />
          <div className="FooterBox">
            <Control
              number={`0${this.state.currentPage}`}
              toNextPage={() => this.toNextPage()}
              toPrevPage={() => this.toPrevPage()}
            />
          </div>
        </div>
      )
    }
  }
}

export default MainLinkBoxGrvina

// animation-delay: 0ms
// animation-delay: 80ms
// animation-delay: 240ms;
// animation-delay: 320ms;
