import React, { Component } from "react";
import "./Style/ForthPage.scss";
import arrow from "../../_asset/images/mainback/Arrow.png";

class ForthPage extends Component {
  contents = [
    {
      title: "BRANDING",
      text: ["Years of experience with", "industry-leading customers"],
      to: "",
    },
    {
      title: "UIUX DESIGN",
      text: ["Consulting for the next-generation", "IT environment"],
      to: "",
    },
    {
      title: "MARKETING",
      text: [
        "Design the best user experience and",
        "avoid unnecessary expenses",
      ],
      to: "",
    },
    {
      title: "MOTION ARTWORK",
      text: ["Data - Driven design design.", "for development"],
      to: "",
    },
    {
      title: "ADVERTISMENT",
      text: ["New team building and main-", "tenance tailored to the project"],
      to: "",
    },
    {
      title: "DEVELOPMENT",
      text: [
        "Continuous investment in R&D",
        "for sustainable future leadership",
      ],
      to: "",
    },
  ];

  renderContent = (contents) =>
    contents.map(({ title, text }) => (
      <div className="RightContentsBox">
        <button className="RightTopBox">
          <div className="RightTitle">{title}</div>
          {/* <img className="arrow" src={arrow} alt="화살표" /> */}
        </button>
        <div className="RightBottomText">
          {text.map((item) => (
            <>
              {item} <br />{" "}
            </>
          ))}
        </div>
      </div>
    ));

  render() {
    return (
      <div className="ForthPageContainer">
        <a href="/Works">
          <div className="ForthPageMain">
            <div className="ForthPageLeftBox">
              <div className="LeftText">View More</div>
              <div className="LeftArrow">&gt;</div>
            </div>
            <div className="ForthPageRightBox">
              {this.renderContent(this.contents)}
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default ForthPage;
