import React, { Component } from 'react';
import {withRouter} from "react-router-dom"
import queryString from 'query-string';
import Motion from './DetailTeam/MotionDetail'
import Design from './DetailTeam/DesignDetail'
import Develop from './DetailTeam/DevelopmentDetail'
import Manage from './DetailTeam/ManagementDetail'
class HiringDetail extends Component {
    state = {
        selectedId: "",
    }
    componentDidMount = () => {
        const queryData = queryString.parse(this.props.location.search);
        this.setState({selectedId: queryData.id});
        console.log(queryData.id)
    }

    render() {
        const {selectedId} = this.state;
        if(selectedId !== ""){
            if(selectedId === "Motion"){
                return <Motion></Motion>
            }
            else if(selectedId === "Design"){
                return <Design></Design>
            }
            else if(selectedId === "Development"){
                return <Develop></Develop>
            }
            else if(selectedId === "Management"){
                return <Manage></Manage>
            } 
        }
        else{
            return <div></div>
        }
        
    }
}

export default withRouter(HiringDetail);