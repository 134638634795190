import axios from "axios";

const baseURL = "https://cdn.contentful.com";

class WorksServive {
  getWorks = async () => {
    const { data } = await axios.get(
      `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
          content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE,
          select: "fields,sys.id",
        },
      }
    );
    let resultList = [];
    const items = data.items;
    const includedAssets = data.includes.Asset;
    const includedEntry = data.includes.Entry;

    resultList = items.map((e) => {
      const images = e.fields.images
        ?.map((item) => item.sys.id)
        .map((item) => {
          return includedAssets.find((itemB) => itemB.sys.id === item)?.fields
            .file.url;
        });
      const thumbnail = includedAssets.find(
        (item) => item.sys.id === e.fields.thumbnail.sys.id
      );
      const category = includedEntry.find(
        (item) => item.sys.id === e.fields.category.sys.id
      );

      return {
        title: e.fields.title,
        id: e.sys.id,
        img: thumbnail.fields.file.url,
        category: category.fields.name,
        images,
        video: e.fields.video,
        sub: e.fields.sub,
      };
    });

    return resultList;
  };

  getCategorys = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
        {
          params: {
            access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
            content_type: "category",
            select: "fields,sys.id",
            "metadata.tags.sys.id[in]":
              process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default new WorksServive();