// import React, { Component } from 'react';
// import './ArtWork.scss';
// import html from './plusxgrid.html';

// class ArtWork extends Component {
//     render() {
//         return (
//             <div className="main-slider__artwork">
//                 <div className='className="overlay-plusxgrid' style={{position: 'absolute', width: '100%', height: '100%'}}></div>
//                 <iframe id='plusxgrid' title='plusxgrid' scrolling='no' src={html} frameBorder='0' className='show'></iframe>
//             </div>
//         );
//     }
// }

// export default ArtWork;
import React, { Component } from 'react';
import video from '../../_asset/images/test/test.mp4';
import './ArtWork.scss';

class ArtWork extends Component {
    render() {
        return (
            <div className='video-wrap'>
                <iframe className='background-video' loop autoPlay>
                    <source src ={video} type='video/mp4'/>
                </iframe>
            </div>

        );
    }
}

export default ArtWork;