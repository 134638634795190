import React, { Component } from "react";
import Prorenata from "../../Experience/Contents/Prorenata/Prorenata";

class ProrenataRouter extends Component {
  render() {
    return (
      <div>
        <Prorenata></Prorenata>
      </div>
    );
  }
}

export default ProrenataRouter;
