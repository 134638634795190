import React, { Component } from "react";
import "./Style/SixPage.scss";
import img from "../../_asset/images/grvina/06-img@3x.png";

class SixPage extends Component {
  state = {
    isMobile: window.innerWidth <= 768,
  };
  handleResize = () => {
    // Update isMobile state based on the current window width
    this.setState({ isMobile: window.innerWidth <= 768 }); // You can adjust the threshold as needed
  };

  componentDidMount() {
    // Initial check on component mount
    this.handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener("resize", this.handleResize);
  }
  render() {
    const { isMobile } = this.state;
    return (
      <div className="SixPageGrvina">
        <div className="SixPageGrvina__title">제품개발</div>
        <img src={img} className="SixPageGrvina__img" />
        {isMobile ? (
          <>
            <div className="SixPageGrvina__desc">
              대량 생산 단가 대비 높은 품질 보장
            </div>
            <div className="SixPageGrvina__desc">
              대량 생산 라인과 경쟁력 있는 단가 소량 생산
            </div>
            <div className="SixPageGrvina__desc">
              신규 제품 개발에 대한 아트, 디자인 팀의 시안을 제공합니다.
            </div>
          </>
        ) : (
          <>
            <div className="SixPageGrvina__desc">
              대량 생산 단가 대비 높은 품질 보장, 대량 생산 라인과 경쟁력 있는
              단가 소량 생산
            </div>
            <div className="SixPageGrvina__desc">
              신규 제품 개발에 대한 아트, 디자인 팀의 시안을 제공합니다.
            </div>
          </>
        )}
      </div>
    );
  }
}

export default SixPage;
