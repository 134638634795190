import React from "react";
import "./style/Pitem.scss";
import Pitemimg from "../../../../_asset/images/Prorenata/img_prn43.png";
import Pitemimg02 from "../../../../_asset/images/Prorenata/img_prn46.png";
import Pitemimg03 from "../../../../_asset/images/Prorenata/img_prn47.png";
import Pitemimg04 from "../../../../_asset/images/Prorenata/img_prn48.png";

const Pitem = () => {
  return (
    <div className="Pitem_container">
      <div className="Pitem_cont_1">
        <img src={Pitemimg} alt="Pitem_image01" />
      </div>
      <div className="Pitem_cont_2"></div>
      <div className="Pitem_cont_3">
        <div className="Pitem_bx_1"></div>
      </div>
      <div className="Pitem_cont_4">
        <img src={Pitemimg02} alt="Pitem_image02" />
      </div>
      <div className="Pitem_cont_5">
        <img src={Pitemimg03} alt="Pitem_image03" />
      </div>
      <div className="Pitem_cont_6">
        <img src={Pitemimg04} alt="Pitem_image04" />
      </div>
      <div className="Pitem_tit container">
        <div className="tit">
          Offline Store items <br></br>
          (Sample / Drucstore Bag / Delivery Box / Tape)
        </div>
      </div>
    </div>
  );
};

export default Pitem;
