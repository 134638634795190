import React from "react";
import "./style/Pvalue.scss";
import valueimg from "../../../../_asset/images/Prorenata/img_prn7.png";
const Pvalue = () => {
  return (
    <div className="Pvalue_container">
      <div className="Pvalue_cont">
        <div className="Pvalue_text_box">
          <div className="Pvalue_tit">Brand Core Values</div>
          <div className="Pvalue_text">
            앞서 정의내린 브랜드 특성과<br></br>
            내부 임직원 인터뷰를 바탕으로 다음과 같은<br></br>
            브랜드 핵심가치를 도출하였습니다.
          </div>
        </div>
        <div className="Pvalue_bg">
          <img src={valueimg} alt="value_bg" />
        </div>
      </div>
    </div>
  );
};

export default Pvalue;
