import React from "react";
import "./style/Psystem.scss";
import image from "../../../../_asset/images/Prorenata/img_prn16.png";

const Psystem = () => {
  return (
    <div className="Psystem_container">
      <div className="Psystem_cont_1">
        <div className="Psystem_txt_bx">
          <div className="Psystem_tit">Product Line System</div>
          <div className="Psystem_txt">
            브랜드의 '처방전' 컨셉에 따라 각 피부 트러블의 증상과 해결방안 또는
            사용법 등 보다 구체적인 내용을<br></br>
            제품 라인 명칭에 적용하여 실제 화장품을 처방받는 듯한 인식을
            제공합니다.
          </div>
        </div>
      </div>
      <div className="Psystem_cont_2">
        <div className="Psystem_img_Wrap">
          <img src={image} alt="Psystem_image" />
        </div>
      </div>
    </div>
  );
};

export default Psystem;
