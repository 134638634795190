import React, { Component } from 'react';
import NoticeData from '../../Common/Id/NoticeId/index';

class News extends Component {
    state = {
        findData: null
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        console.log(NoticeData);
        this.setState({
            findData: NoticeData.list.find(item => item.id === id)
        })
    }
    render() {
        if(this.state.findData){
            return <this.state.findData.contents></this.state.findData.contents>
        }
        
        else{
            return <div></div>
        }
        
    }
}

export default News;