import React, { Component } from 'react'
import './HeaderGrvina.scss'
import HeaderLogo from '../HeaderLogo/HeaderLogo'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'

class HeaderGrvina extends Component {
  render() {
    const { isLogoBlack, currentPage } = this.props
    console.log(currentPage)
    return (
      <header
        className={`headerGrvina ${
          currentPage === 1 ? 'headerGrvina__backgroundBlack' : ''
        }`}
      >
        <div className="headerGrvina__wrapper">
          <HeaderLogo isLogoBlack={isLogoBlack}></HeaderLogo>
          <HamburgerMenu isLogoBlack={isLogoBlack}></HamburgerMenu>
        </div>
      </header>
    )
  }
}

export default HeaderGrvina
