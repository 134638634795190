import React, { Component } from 'react'
import './Style/SecondPage.scss'
import '../../Common/LinkClick/LinkClick.scss'
import video from '../../_asset/lottie/second.mp4'
import bgvideo from '../../_asset/lottie/second_bg.mp4'
// import footerAnimationMobile from '../../_asset/animation/landing_07_mobile.json';
import Media from 'react-media'
import LinkClick from '../../Common/LinkClick/LinkClick'
import backimg from '../../_asset/images/mainback/bg-02@3x.png'
import TypeIt from 'typeit-react'
import Slider from 'react-slick'
import imgColab1 from '../../_asset/images/grvina/img-collabo-01@3x.webp'
import imgColab2 from '../../_asset/images/grvina/img-collabo-02@3x.webp'
import imgColab3 from '../../_asset/images/grvina/img-collabo-03@3x.webp'
import imgColab4 from '../../_asset/images/grvina/img-collabo-04@3x.webp'
import imgColab5 from '../../_asset/images/grvina/img-collabo-05@3x.webp'
import icon01 from '../../_asset/images/grvina/ic-collabo-01@3x.png'
import icon02 from '../../_asset/images/grvina/ic-collabo-02@3x.png'
import icon03 from '../../_asset/images/grvina/ic-collabo-03@3x.png'
import icon04 from '../../_asset/images/grvina/ic-collabo-04@3x.png'

// api fullpage.js 문서 참고 : https://github.com/alvarotrigo/fullPage.js#lazy-loading
// data-autoplay =  Lazy loading 형태로 Section에 진입해야지만 재싱되도록 설정
// data-keepplaying = section이동시 자동으로 멈추는 Default 값을 끄는 옵션 (지속적으로 Background 에서 재생)
// data-src = 소스 파일을 Lazy loading 처리하는 옵션

class SecondPage extends Component {
  state = {
    isMobile: window.innerWidth <= 768,
  }
  handleResize = () => {
    // Update isMobile state based on the current window width
    this.setState({ isMobile: window.innerWidth <= 768 }) // You can adjust the threshold as needed
  }

  componentDidMount() {
    // Initial check on component mount
    this.handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { isMobile } = this.state
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: null,
      prevArrow: null,
      variableWidth: true,
      centerPadding: '45px',
      centerMode: true,
      initialSlide: 2,
    }
    return (
      <div className="SecondPageGrvina">
        <section style={{}}></section>
        <p className="SecondPageGrvina__title">협력 및 콜라보레이션 제안</p>
        <p className="SecondPageGrvina__desc">
          운동 시장의 확장과 더불어 브랜드 양말들의 다양성과 디자인 퀄리티도
          지속적으로 향상되고 있습니다.
        </p>
        {isMobile ? (
          <Slider {...settings}>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab1} alt="" />
              <img className="SecondPageGrvina__icon1" src={icon01} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab2} alt="" />
              <img className="SecondPageGrvina__icon2" src={icon02} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab3} alt="" />
              <img className="SecondPageGrvina__icon1" src={icon03} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab4} alt="" />
              <img className="SecondPageGrvina__icon3" src={icon04} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab5} alt="" />
            </div>
          </Slider>
        ) : (
          <Slider {...settings}>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab1} alt="" />
              <img className="SecondPageGrvina__icon1" src={icon01} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab2} alt="" />
              <img className="SecondPageGrvina__icon2" src={icon02} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab3} alt="" />
              <img className="SecondPageGrvina__icon1" src={icon03} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab4} alt="" />
              <img className="SecondPageGrvina__icon3" src={icon04} alt="" />
            </div>
            <div className="SecondPageGrvina__slider-item">
              <img className="SecondPageGrvina__image" src={imgColab5} alt="" />
            </div>
          </Slider>
        )}
      </div>
    )
  }
}

export default SecondPage
