import React, { useState, useEffect } from "react";
import styled from "styled-components";
import creditimg from "../../../../_asset/images/29TV/img_footer.png";
import "./style/credit.scss";
import creditteam from "../../../../_asset/images/29TV/txt_footername.png";

const Text = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  margin: 215px 0 100px;
  > img {
    width: 326px;
    height: 701px;
  }
`;
const Texttwo = styled.div`
  margin-bottom: 100px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 32px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1.7s linear;
`;

const Credit = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 33000) {
      setTrigger(true);
    }
  }, [pageY]);
  return (
    <div className="creditcontainer">
      <div className="credit_top">
        <img src={creditimg} alt="credit_image" />
        <Text trigger={trigger}>
          <img src={creditteam} alt="credit_image" />
        </Text>
        <Texttwo trigger={trigger}>
          본 프로젝트는 29CM의 주도로 긴밀하게 커뮤니케이션하면서 Plus X가
          제안한 컨설팅 내용이며,
          <br />
          현재 29CM에서 실제 서비스되고 있는 29TV와는 상이한 부분이 있을 수
          있습니다
        </Texttwo>
      </div>
    </div>
  );
};

export default Credit;
