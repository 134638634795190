import React from "react";
import Slider from "react-slick";
import "./style/PslickPage.scss";
import slickimg01 from "../../../../_asset/images/Prorenata/slick/img_prn3.png";
import slickimg02 from "../../../../_asset/images/Prorenata/slick/img_prn4.png";
import slickimg03 from "../../../../_asset/images/Prorenata/slick/img_prn5.png";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PslickPage = () => {
  return (
    <div className="PslickPage_container">
      <div className="slick_box">
        <Slider {...settings}>
          <div className="swiper-slide">
            <img src={slickimg01} alt="slick_image"></img>
          </div>
          <div className="swiper-slide">
            <img src={slickimg02} alt="slick_image"></img>
          </div>
          <div className="swiper-slide">
            <img src={slickimg03} alt="slick_image"></img>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default PslickPage;
