import React, { Component } from 'react';
import './DetailTeam.scss'
import more from '../../../_asset/images/WhiteBack/more-icon.svg';
import arrow from '../../../_asset/images/WhiteBack/next-icon.svg';
import banner from '../../../_asset/images/WhiteBack/hiring-banner-01.png';
import HiringPopUp from '../../PopUp/HiringPopUp'

class DesignDetail extends Component {
    list = [
        { text : "서류<br/>전형", img : arrow},
        { text : "서류<br/>심사", img : arrow},
        { text : "1차<br/>면접", img : arrow},
        { text : "직무 역량<br/>테스트", img : arrow},
        { text : "2차<br/>최종면접<br/>및 확정", img : {}},
    ]
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
        index: 1
    }
    handleDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            isOneDialogOpen: false
        })
    }
    buttonClick =  (index) => {
        this.setState({
            index,
        }, () => this.setState({isDialogOpen: true}))
    }
    render() {
        return (
            <div className='DetailTeamContainer'>
                <div className='DetailTeamMain'>
                    <div className='DetailTeamList Detail'>
                        <div className='DetailTeamTitleBox'>
                            <div className='DetailTeamTitle'>
                                Hiring Detail
                            </div>
                            <div className='DetailTeamTeam'>
                                Desigm Team
                            </div>
                        </div>
                        <div className='DetailTeamSubImg'>
                            <img src={banner} alt="배너 이미지" />
                        </div>
                    </div>
                    <div className='DetailTeamList Intro'>
                        <div className='DetailTeamTitle'>
                            Team Introducing
                        </div>
                        <div className='DetailTeamSub Intro'>
                            <div className='DetailTeamSubText'>
                                경험의 가치를 알고 그 가치를 소중히 생각하는 여러분과 함께하게 되어 행복합니다. 
                                루크에이젼시는 다양한 경험을 바탕으로 비지니스를 발전시키며 자체 플랫폼과 브랜드를 개발 및 런칭해왔습니다. 
                                우리의 경험을 공유하며 다양한 파트너들을 만나 에이젼시로서 크게 발전하고 있습니다. 
                                함께 경험을 나눌 파트너들을 위해 루크에이젼시는 항상 열려있습니다.
                            </div>
                                <button className='BtnBox'>
                                    <div className='IntroBtn'>
                                        팀 작업물 보러가기
                                    </div>
                                    <a href='/works'/>
                                    <img src={more} alt='더보기' />
                                </button>
                        </div>
                    </div>
                    <div className='DetailTeamList Work'>
                        <div className='DetailTeamTitle'>
                            Work
                        </div>
                        <div className='DetailTeamSub'>
                            -  다양한 그래픽 디자인 업무 경력자<br/>
                            -  3D 콘텐츠 디자인이 가능 디자이너<br/>
                            -  유니티 3D 혹은 언리얼 등을 이용한 배경 디자인이 가능한 분
                        </div>
                    </div>
                    <div className='DetailTeamList Want'>
                        <div className='DetailTeamTitle'>
                            Want
                        </div>
                        <div className='DetailTeamSub'>
                            -  새로운 시도, 도전을 즐기고 다양한 생각과 영감을 공유할 줄 아시는 디자이너 분<br/>
                            -  협업을 존중하고 커뮤니케이션을 통한 문제해결을 즐길 줄 아시는 디자이너 분<br/>
                            -  열정적이고 재치 있는 디자이너 분<br/>
                            -  UX, UI 그래픽 디자인 및 디자인 툴을 활용한 모든 비주얼 표현이 능숙하신 분<br/>
                            -  다양한 아이디어와 기획, 연출등을 시각화하여 커뮤니케이션 할 수 있는 디자이너 분
                        </div>
                    </div>
                    <div className='DetailTeamList Give'>
                        <div className='DetailTeamTitle'>
                            Give Special
                        </div>
                        <div className='DetailTeamSub'>
                            -  다양한 그래픽 디자인 업무 경력자<br/>
                            -  3D 콘텐츠 디자인이 가능 디자이너<br/>
                            -  유니티 3D 혹은 언리얼 등을 이용한 배경 디자인이 가능한 분
                        </div>
                    </div>
                    <div className='DetailTeamList Documents'>
                        <div className='DetailTeamTitle'>
                            Supporting <br/>
                            Documents
                        </div>
                        <div className='DetailTeamSub'>
                            -  이력서 및 자기소개서<br/>
                            -  포트폴리오 (최근 작업물 순으로 제출, 본인기여부분 기재, PDF/PPT 형식으로 제출)
                        </div>
                    </div>
                    <div className='DetailTeamList Process'>
                        <div className='DetailTeamTitle'>
                            Hiring <br/>
                            Process
                        </div>
                        <div className='DetailTeamSub'>
                            {this.list.map((item, index) => {
                                return (
                                    <div className='HiringList' key={index}>
                                        <div className='HiringListCircle'> 
                                            <div dangerouslySetInnerHTML={ {__html: item.text} } />
                                        </div>
                                        <img className='HiringListImg' src={item.img} alt='화살표' />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='DetailTeamList Documents'>
                        <div className='DetailTeamTitle'>
                            Supporting <br/>
                            Documents
                        </div>
                        <div className='DetailTeamSub'>
                            -  수시채용<br/>
                            -  신입 [전공자, 비전공자 무관]<br/>
                            -  경력 [업계 경력 3년 이상]
                        </div>
                    </div>
                    <button onClick={() => this.buttonClick()} className='DetailTeamFormBtn'>
                        채용 지원 하기
                    </button>
                </div>
                <HiringPopUp
                    open={this.state.isDialogOpen}
                    onClickPopupNext ={this.handleClickPopupNext}
                    onClose={this.handleDialogClose}
                    index={this.state.index}
                />
            </div>
        );
    }
}

export default DesignDetail;