import React, { useState, useEffect } from "react";
import "./style/Ecaltalogue.scss";
import styled from "styled-components";
import catalogueimg from "../../../../_asset/images/emart/tit_catalogue.png";
import catalogue01 from "../../../../_asset/images/emart/img_catalogue1.png";
import catalogue02 from "../../../../_asset/images/emart/img_catalogue2.png";
import catalogue03 from "../../../../_asset/images/emart/img_catalogue3.png";
import shape from "../../../../_asset/images/emart/img_shape.png";
// import slider from "../../../../_asset/images/emart/img_card_catalogue.png";

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const ListItemOne = styled.div`
  margin-top: 348px;
  margin-bottom: 68px;
  display: inline-block;
  margin-right: 68px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
  > img {
    vertical-align: middle;
  }
`;

const ListItemTwo = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Ecatalogue = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 20000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Ecataloguecontainer">
      <div className="con_card">
        <div className="tit_bx">
          <Title trigger={trigger}>
            <img src={catalogueimg} alt="catalogue_image" />
            <div className="sub_text">
              이번주엔 뭐해먹을까? <br></br>
              지금 계절엔 어떤 식품이 좋지?<br></br>
              지금 할인받을 수 있는 상품은?<br></br>
              전단 광고는 먹거리&생활 용품 쇼핑에 대한<br></br>
              고객의 고민거리를 덜어줍니다.<br></br>
              여기서는 이미지 가이드를 제공하는 방향으로<br></br>
              개선하여, 고객이 제한된 화면에서 상품콘텐츠에<br></br>
              집중할 수 있도록 시인성을 높였습니다.
            </div>
          </Title>
          <span className="bg"></span>
        </div>
        <div className="card">
          <ListItemOne trigger={trigger}>
            <img src={catalogue01} alt="catalogue_image01" />
          </ListItemOne>
          <ListItemTwo trigger={trigger}>
            <div className="catalogueWrap">
              <img src={catalogue02} alt="catalogue_image02" />
            </div>
            <img src={catalogue03} alt="catalogue_image03" />
          </ListItemTwo>
        </div>
        <div className="txt">
          <div className="txt_bottom">
            At a glance <br></br>
            One Page Catalogue
          </div>
          <img src={shape} alt="catalogue_shape" />
        </div>
      </div>
    </div>
  );
};

export default Ecatalogue;
