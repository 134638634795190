import React, { Component } from 'react'
import './Style/ElevenPage.scss'
import '../../Common/LinkClick/LinkClick.scss'
import request from '../../_asset/images/grvina/request@3x.png'

class ElevenPage extends Component {
  handleClickBoard = () => {
    navigator.clipboard.writeText('inform.no.mad@gmail.com')
    alert('이메일이 복사되었습니다.')
  }
  render() {
    return (
      <div
        className="ElevenPage"
        style={{ width: '100%', height: '100%', color: '#FFF' }}
      >
        <div className="ElevenPage__content">
          <div className="ElevenPage__title">Design it now</div>
          <div className="ElevenPage__desc">
            Always focus on and analyze trends that are rapidly
          </div>
          <div className="ElevenPage__desc">
            evolving to produce optimal results.
          </div>
          <div className="ElevenPage__img">
            <img src={request} alt="" className="ElevenPage__img" />
          </div>
          <a href="mailto:Inform.no.mad@gmail.com" className="ElevenPage__link">
            Inform.no.mad@gmail.com
          </a>
        </div>
      </div>
    )
  }
}

export default ElevenPage
