import React, { Component } from 'react'
import './Style/FirstPage.scss'
import '../../Common/LinkClick/LinkClick.scss'
import backimg from '../../_asset/images/mainback/bg-01@3x.png'
import top_img from '../../_asset/images/grvina/top-img@3x.webp'
class FirstPage extends Component {
  render() {
    return (
      <div
        className="FirstPageGrvina"
        style={{ width: '100%', height: '100%', color: '#FFF' }}
      >
        <div className="FirstPageGrvina__content">
          <p className="FirstPageGrvina__title">제조 브랜드들이 증명합니다.</p>

          <p className="FirstPageGrvina__text">
            다양한 브랜드 <span className='FirstPageGrvina__green'>제품을 성공적으로 생산</span>해왔으며,
          </p>
          <p className="FirstPageGrvina__text">현재는 요넥스, 엄브로, 르꼬끄, 하덱스, JDX 등의</p>
          <p className="FirstPageGrvina__text">프리미엄 제품들을 제조하고 있습니다.</p>
        </div>
        <div className="BackImg">
          <img src={top_img} alt="Background-image" />
        </div>
      </div>
    )
  }
}

export default FirstPage
