import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/connect.scss";
import connect01 from "../../../../_asset/images/29TV/img_connect01.jpg";
import connect02 from "../../../../_asset/images/29TV/img_connect03.jpg";
import connect03 from "../../../../_asset/images/29TV/img_connect05.jpg";
import connect04 from "../../../../_asset/images/29TV/img_connect02.jpg";
import connect05 from "../../../../_asset/images/29TV/img_connect04.jpg";

const Title = styled.div`
  font-size: 55px;
  text-align: left;
  letter-spacing: 15px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.2s linear;
  transition: transform 1.2s linear;
`;
const Ullist = styled.div`
  display: flex;
  margin: 196px 0 0 0;
  padding-left: 13.2vw;
  white-space: nowrap;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
`;

const Connect = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 26300) {
      setTrigger(true);
    }
  }, [pageY]);
  return (
    <div className="connectcontainer">
      <div className="connect_top">
        <Title trigger={trigger}>
          CONNECT
          <br />
          BRAND
          <br />
          AND CUSTOMER
        </Title>
        <Subtitle trigger={trigger}>
          저희가 제안한 29CM 2.0은 브랜드와 크리에이터를 포함한 모든 고객을
          유기적으로 연결합니다.
          <br />
          고객은 29TV를 통해 라이프스타일과 브랜드를 소비하고 브랜드는 브랜드가
          소비되는 패턴을 통해 현황을 진단하여 <br />
          효과적인 커뮤니케이션 방법을 모색할 수 있습니다.
        </Subtitle>
        <Ullist trigger={trigger}>
          <div className="connect_image01">
            <img src={connect01} alt="connectimg01" />
            <img src={connect04} alt="connectimg02" />
          </div>
          <div className="connect_image02">
            <img src={connect02} alt="connectimg03" />
            <img src={connect05} alt="connectimg04" />
          </div>
          <div className="connect_image03">
            <img src={connect03} alt="connectimg05" />
          </div>
        </Ullist>
      </div>
    </div>
  );
};

export default Connect;
