import React, { Component } from 'react';
import './Header.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';


class Header extends Component {
    render() {
        const { isLogoBlack } = this.props;
        return (
            <header className="header">
                <div className="header__wrapper">
                    <HeaderLogo isLogoBlack={isLogoBlack}></HeaderLogo>
                    <HamburgerMenu isLogoBlack={isLogoBlack}></HamburgerMenu>
                </div>
            </header>
        );
    }
}

export default Header;