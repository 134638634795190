import React from "react";
import "./style/Pbrand.scss";
import listimg from "../../../../_asset/images/Prorenata/img_prn9.png";

const Pbarnd = () => {
  return (
    <div className="Pbrand_container">
      <div className="Pbrand_cont">
        <div className="Pbrand_text_bx">
          <div className="Pbrand_tit">Brand Design Principle</div>
          <div className="Pbrand_txt">
            프로레나타의 브랜드 디자인은<br></br>꼭 필요한 핵심 정보와 메시지를
            간결하고 <br></br>
            명료하게 전달하며, 우수한 효능과 앞선 <br></br>
            기술의 전문성을 세련되게 표현합니다.<br></br>
            나아가, 모든 경험 접점에서 주목성 있고 <br></br>
            생동감 있는 룩을 연출합니다.
          </div>
          <div className="Pbrand_list">
            <img src={listimg} alt="Pbrand_list_image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pbarnd;
