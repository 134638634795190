import React, { Component } from 'react';
import './HamburgerMenuWhite.scss';
import Menu from '../Menu/Menu';
import about from '../../_asset/images/WhiteBack/about-2-icon.svg';
import download from '../../_asset/images/WhiteBack/download-2-icon.svg';
import menu from '../../_asset/images/WhiteBack/menu-2.svg';

class HamburgerMenuWhite extends Component {
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
    }
    buttonClick = () => {

        // 모달 보이고 감추기 Toggle
        this.setState(prevState => ({
            isDialogOpen: !prevState.isDialogOpen
        }));
    }
    
    
    render() {
        return (
            <div className='ButtonWhiteContainer'>
                <button className="btn about" >
                    <a href='/about'>
                            <div className='menubtn'>about</div>
                            <img src={about} alt=''></img>
                    </a>
                </button>
                {/* <button className="btn">
                    <a href='/#firstpage'>
                        <div className='menubtn'>download</div>
                        <img src={download} alt=''></img>
                    </a>
                </button> */}
               <button onClick={this.buttonClick} className="btn">
                    <div  className='menubtn'>menu</div>
                    <svg className='menuIcon' xmlns="http://www.w3.org/2000/svg" width='14' height="14">
                        <path fillRule="evenodd" d="M2,4V3h10v1H2z M10,8H2V7h8V8z M7,12H2v-1h5V12z"/>
                    </svg>
                    <Menu open={this.state.isDialogOpen}
                   />
                </button>
            </div>
        );
    }
}

export default HamburgerMenuWhite;