import React, { useState, useEffect } from "react";
import "./style/Econcept.scss";
import styled from "styled-components";
import img_bg from "../../../../_asset/images/emart/bg_conception.png";

const ConBox = styled.div`
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Econcept = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 9000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Econceptcontainer">
      <div className="Econceptcontainer-bg">
        <img src={img_bg} alt="" />
      </div>
      <ConBox trigger={trigger}>
        <div className="litle_title">CONCEPT</div>
        <div className="title">Ready to go Here</div>
        <div className="text">
          이마트 앱/웹은, 이마트 가기 전에 한 번에 훑어보는, 이마트 가기 전에 꼭
          확인하는 <br></br>
          이마트 가기 전에 장볼 것을 챙기는, 이마트에 갈 준비를 도와주는
          서비스여야 합니다.
        </div>
      </ConBox>
    </div>
  );
};

export default Econcept;
