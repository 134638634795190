import React, { Component } from "react";
import "./AboutTop.scss";
import firstimg from "../../../_asset/images/mainback/about-img-1@3x.png";
import LeftImg from "../../../_asset/images/mainback/about-img-2@2x.png";
import RightImg from "../../../_asset/images/mainback/about-img-3@2x.png";

class AboutTop extends Component {
  render() {
    return (
      <div className="AboutTopContainer">
        <div className="AboutTopMain">
          <div className="AboutTopTitleBox">
            <div className="FontBox">
              <div className="RightText">
                NO-MAD
                <br />
                NEVER
                <br />
                STOP MOVING
              </div>
              <div className="LeftTextBox">
                <div className="LeftText">
                  It is a complex cultural space <br />
                  in the city center that <span>connects</span> people
                  <br />
                  to <span>various</span> fields
                </div>
              </div>
            </div>
          </div>
          <div className="FirstImgBox">
            <img className="firstimg" src={firstimg} alt="first" />
          </div>
          <div className="ExperienceContainer">
            <div className="ExprienceMain">
              <div className="ExprienceTextBox">
                <div className="Value">01 / First Value.</div>
                <div className="Title">Experience</div>
                <div className="SubText">
                  Uix has evolved through many experiences.
                  <br />
                  Everything we do will have reliable results.
                  <br />
                  Practical and reliable work,
                  <br />
                  The abilities that have been created by experience.
                  <br />
                  It's the power we've built up.
                </div>
              </div>
              <div className="ExprienceImgBox">
                <div className="LeftImgBox">
                  <img className="LeftImg" src={LeftImg} alt="LeftImg" />
                  <div className="ImgText">DETAIL VIEW. CONTACT</div>
                </div>
                <div className="RightImgBox">
                  <img className="RightImg" src={RightImg} alt="RightImg" />
                  <div className="ImgText">DETAIL VIEW. CONTACT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutTop;
