import React, { Component } from 'react';
import Contact from '../../Home/Contact/Contact';
import FooterMain from "../../Common/FooterMain/FooterMain";
import HeaderMain from "../../Common/HeaderMain/HeaderMain";


class RouterContact extends Component {
    render() {
        return (
            <div>
                <HeaderMain />
                <Contact />
                <FooterMain />
            </div>
        );
    }
}

export default RouterContact;