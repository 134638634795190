import React, { Component } from 'react'
import './Style/EightPage.scss'
import imgBox from '../../_asset/images/grvina/08-box-3@3x.png'
import arrow from '../../_asset/images/grvina/08-arrow-down-3@3x.png'
import sock from '../../_asset/images/grvina/08-sock@3x.webp'

class EightPage extends Component {
  render() {
    return (
      <div className="EightPageGrvina">
        <div className="EightPageGrvina__title">제품 고려 사항</div>

        <div className="EightPageGrvina__content">
          <div className="EightPageGrvina__item">
            <img className="EightPageGrvina__box" alt="" src={imgBox} />
            <img className="EightPageGrvina__arrow" alt="" src={arrow} />
            <div className="EightPageGrvina__heading">질감 디자인</div>
            <div className="EightPageGrvina__text">
              <div className="EightPageGrvina__text1">무지</div>
              <div className="EightPageGrvina__text1">쿠션</div>
              <div className="EightPageGrvina__text1">기하학적 무늬</div>
            </div>
          </div>
          <div className="EightPageGrvina__item">
            <img className="EightPageGrvina__box" alt="" src={imgBox} />
            <img className="EightPageGrvina__arrow" alt="" src={arrow} />
            <div className="EightPageGrvina__heading">이미지, 로고 디자인</div>
            <div className="EightPageGrvina__text">
              <div className="EightPageGrvina__text1">로고/자수 </div>
              <div className="EightPageGrvina__text1">링글/문양</div>
            </div>
          </div>
          <div className="EightPageGrvina__item">
            <img className="EightPageGrvina__box" alt="" src={imgBox} />
            <img className="EightPageGrvina__arrow" alt="" src={arrow} />
            <div className="EightPageGrvina__heading">패턴원사</div>
            <img alt="" src={sock} className="EightPageGrvina__sock"></img>
          </div>
        </div>
        <div className="EightPageGrvina__desc">디자인의 확정 / 원사준비</div>
      </div>
    )
  }
}

export default EightPage
