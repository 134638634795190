
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReauestImg from '../../../src/_asset/images/mainback/reauest-project-main-img@3x.png'
import WhiteButton from '../../../src/_asset/images/mainback/white-download-icon.svg'
import BlackButton from '../../../src/_asset/images/mainback/black-download-icon.svg'
import ElementButton from '../../../src/_asset/images/mainback/white-line-circle-icon.svg'
import ElementCheckButton from '../../../src/_asset/images/mainback/selected-line-circle-icon.svg'
import DownArrow from '../../../src/_asset/images/mainback/gray-downdrop-icon.svg'
import GrayCircle from '../../../src/_asset/images/mainback/gray-line-circle-icon.svg'
import BasicPage from './Page/BasicPage';
import SharePage from './Page/SharePage';
import RequiredPage from './Page/RequiredPage';
import EnterProjectPage from './Page/EnterProjectPage';
import HeaderImg from '../../../src/_asset/images/mainback/reauest-project-main-img@3x.png'


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));



class Reauest extends Component {
    state = {
        agreeState: false,
        ComBtn: false,
        WebBtn: false,
        BrandingBtn: false,
        fitstList: [
            {
                id: 1,
                title: "Annual Operation",
            },
            {
                id: 2,
                title: " Reactive Web",
            },
            {
                id: 3,
                title: " Photography",
            },
            {
                id: 4,
                title: "Search Engin Optimization (SEO)",
            },



        ],
        secondList: [
            {
                id: 5,
                title: "Character Design",
            },
            {
                id: 6,
                title: "Illustration Design",
            },
            {
                id: 7,
                title: "Search Engine Optimization (SEO)",
            },
            {
                id: 8,
                title: "Character Design",
            },
        ],
        thirdList: [
            {
                id: 9,
                title: "Development of WordPress Custom Theme",
            },
            {
                id: 10,
                title: "Maintenance",
            },
            {
                id: 11,
                title: "Shopping mall",
            },
            {
                id: 12,
                title: "Other",
                subtitle: "*Please write down the details in detail",
            },
        ],

        checkedList: []
    }
    // classes = useStyles();
    handleAgreeCheck = () => {
        if (this.state.agreeState) {
            this.setState({ agreeState: false })
        } else {
            this.setState({ agreeState: true })
        }
    }
    handleCheckedItem = (value) => {

        if (
            !this.state.checkedList.find((item) => item === value)
        ) {
            let checkedList = this.state.checkedList;
            checkedList.push(value);
            this.setState({ checkedList });
        } else {
            let checkedList = this.state.checkedList;
            checkedList = checkedList.filter((item) => item !== value);
            this.setState({ checkedList });
        }
    };
    render() {
        return (

            <div>
                <div className="RequestMainBox">
                    <img className='HeaderImg' src={HeaderImg} alt='HeaderImg' />
                    <div className="AllPage">
                        <div className="AllPage__wrapper">
                            <SharePage />
                            <RequiredPage />
                            <EnterProjectPage />
                            <BasicPage />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reauest;