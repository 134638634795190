import React from "react";
import "./style/Ptext.scss";
import image from "../../../../_asset/images/Prorenata/txt_prn9.png";

const Ptext = () => {
  return (
    <div className="Ptext_container">
      <div className="Ptext_cont">
        <div className="Image_Wrap">
          <img src={image} alt="Ptext_Image" />
        </div>
      </div>
    </div>
  );
};

export default Ptext;
