import React, { useEffect } from "react";
import Pcover from "./function/Pcover";
import Poverflow from "./function/Poverflow";
import Pfeature from "./function/Pfeature";
import PslickPage from "./function/PslickPage";
import Pdifferentiation from "./function/Pdifferentiation";
import Pvalue from "./function/Pvalue";
import PbgPage from "./function/PbgPage";
import Pbarnd from "./function/Pbarnd";
import PbgPageTwo from "./function/PbgPageTwo";
import PbgPageThree from "./function/PbgPageThree";
import Pconcept from "./function/Pconcept";
import Pidentify from "./function/Pidentify";
import Ptext from "./function/Ptext";
import PbgPageFour from "./function/PbgPageFour";
import Psystem from "./function/Psystem";
import Pcolor from "./function/Pcolor";
import PimageChage from "./function/PimageChage";
import Ptypography from "./function/Ptypography";
import Pmotif from "./function/Pmotif";
import Picons from "./function/Picons";
import Pdesign from "./function/Pdesign";
import PbgPageFive from "./function/PbgPageFive";
import Ppictures from "./function/Ppictures";
import Ptender from "./function/Ptender";
import Pcard from "./function/Pcard";
import Pitem from "./function/Pitem";
import Pphoto from "./function/Pphoto";
import Pgift from "./function/Pgift";
import Pinstagram from "./function/Pinstagram";
import Pphone from "./function/Pphone";
import Plast from "./function/Plast";
import "./Prorenata.scss";

const Prorenata = () => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "NotoSerifKR";
  });
  return (
    <div className="Prorenata_container">
      <Pcover></Pcover>
      <Poverflow></Poverflow>
      <Pfeature></Pfeature>
      <PslickPage></PslickPage>
      <Pdifferentiation></Pdifferentiation>
      <Pvalue></Pvalue>
      <PbgPage></PbgPage>
      <Pbarnd></Pbarnd>
      <PbgPageTwo></PbgPageTwo>
      <PbgPageThree></PbgPageThree>
      <Pconcept></Pconcept>
      <Pidentify></Pidentify>
      <Ptext></Ptext>
      <PbgPageFour></PbgPageFour>
      <Psystem></Psystem>
      <Pcolor></Pcolor>
      <PimageChage></PimageChage>
      <Ptypography></Ptypography>
      <Pmotif></Pmotif>
      <Picons></Picons>
      <Pdesign></Pdesign>
      <PbgPageFive></PbgPageFive>
      <Ppictures></Ppictures>
      <Ptender></Ptender>
      <Pcard></Pcard>
      <Pitem></Pitem>
      <Pphoto></Pphoto>
      <Pgift></Pgift>
      <Pinstagram></Pinstagram>
      <Pphone></Pphone>
      <Plast></Plast>
    </div>
  );
};

export default Prorenata;
