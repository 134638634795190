import React, { Component } from 'react';
import "./Style/RequiredPage.scss"
import "./../Reauest.scss"
import ElementButton from '../../../../src/_asset/images/mainback/white-line-circle-icon.svg'
import ElementCheckButton from '../../../../src/_asset/images/mainback/selected-line-circle-icon.svg'
import CheckboxButton from '../../../Components/CheckboxButton/CheckboxButton.js';
import { Grid } from '@material-ui/core';


class SharePage extends Component {
    state = {
        agreeState: false,
        ComBtn: false,
        WebBtn: false,
        BrandingBtn: false,
        checkedList: []
    }
    contents = [{
        id: 1,
        label: "Annual Operation",
    },
    {
        id: 2,
        label: " Reactive Web",
    },
    {
        id: 3,
        label: " Photography",
    },
    {
        id: 4,
        label: "Search Engin Optimization (SEO)",
    },
    {
        id: 5,
        label: "Character Design",
    },
    {
        id: 6,
        label: "Illustration Design",
    },
    {
        id: 7,
        label: "Search Engine Optimization (SEO)",
    },
    {
        id: 8,
        label: "Character Design",
    }, {
        id: 9,
        label: "Development of WordPress Custom Theme",
    },
    {
        id: 10,
        label: "Maintenance",
    },
    {
        id: 11,
        label: "Shopping mall",
    },
    {
        id: 12,
        label: "Other",
        requiredText: "*Please write down the details in detail",
    },
    ]
    handleChange = (id) => {
        const updatedCheckedList = [...this.state.checkedList];
        const checkedIndex = updatedCheckedList.findIndex((checkedItem) => checkedItem === id);
        if (checkedIndex < 0) {
            updatedCheckedList.push(id);
        } else {
            updatedCheckedList.splice(checkedIndex, 1);
        }
        this.setState({
            checkedList: updatedCheckedList
        })
    }
    renderContents = (contents) => {
        return contents.map((content, index) => (
            <Grid key={index} item xs={6} md={4}>
                <div className="buttonWrapper">
                    <CheckboxButton name="requiredServices" {...content} handleChange={this.handleChange} />
                </div>
            </Grid>
        ))
    }
    render() {
        return (
            <div>
                <div className='RequiredServicesBox'>

                    <div className='RequiredContainer'>

                        <div className='RequiredServicesTitle'>
                            Required<br />
                            services
                            </div>

                        <div className='RequiredExplanation'>
                            *Required services (Please select <span>one or more</span>)
                                                </div>

                        {/* <div className='RequiredElementBox'>

                            <div className='FirstRequiredElement'>
                                {this.state.fitstList.map((item) => {
                                    return (
                                        <div className='Element' onClick={() => this.handleCheckedItem(item.id)}>
                                            <img className="ElementButton" src={this.state.checkedList.find((data) => data === item.id) ? ElementCheckButton : ElementButton} alt='ElementCheckButton' />
                                            {item.title}
                                        </div>
                                    )
                                })}

                            </div>

                            <div className='SecondRequiredElement'>
                                {this.state.secondList.map((item) => {
                                    return (
                                        <div className='Element' onClick={() => this.handleCheckedItem(item.id)}>
                                            <img className="ElementButton" src={this.state.checkedList.find((data) => data === item.id) ? ElementCheckButton : ElementButton} alt='ElementButton' />
                                            {item.title}
                                        </div>
                                    )
                                })}



                            </div>

                            <div className='ThirdRequiredElement'>
                                {
                                    this.state.thirdList.map((item) => {
                                        return (
                                            item.subtitle ?
                                                <div className='OtherElement' onClick={() => this.handleCheckedItem(item.id)}>
                                                    <img className="ElementButton" src={this.state.checkedList.find((data) => data === item.id) ? ElementCheckButton : ElementButton} alt='ElementButton' />
                                                    <div className='OtherBox'>
                                                        <div className='OtherTitle'>
                                                            {item.title}
                                                        </div>
                                                        <div className='SmallElement'>
                                                            {item.subtitle};
                            </div>
                                                    </div>

                                                </div>
                                                : <div className='Element' onClick={() => this.handleCheckedItem(item.id)}>
                                                    <img className="ElementButton" src={this.state.checkedList.find((data) => data === item.id) ? ElementCheckButton : ElementButton} alt='ElementButton' />
                                                    {item.title}
                                                </div>

                                        )
                                    })
                                }

                            </div>

                        </div> */}
                        <div className="required__content">
                            <Grid container>
                                {this.renderContents(this.contents)}
                            </Grid>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default SharePage;