import React from "react";
import "./style/PbgPage.scss";

const PbgPage = () => {
  return (
    <div className="PbgPage_container">
      <div className="Pbg_cont">
        <div className="Pbg_text_bx">
          <div className="Pbg_text">
            We stick to products that can feel pride based on <br></br>
            good natural materials and advantaged technology <br></br>
            and never compromise anything for customer's <br></br>
            heathly and beautiful life
          </div>
        </div>
      </div>
    </div>
  );
};

export default PbgPage;
