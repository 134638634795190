import React, { useState, useEffect } from "react";
import "./style/Elayout.scss";
import Elayoutimg from "../../../../_asset/images/emart/tit_home.png";
import styled from "styled-components";
import layout01 from "../../../../_asset/images/emart/img_layout1.png";
import layout02 from "../../../../_asset/images/emart/img_layout2.png";
import layout03 from "../../../../_asset/images/emart/img_layout3.png";
import layout04 from "../../../../_asset/images/emart/img_layout4.png";
import layout05 from "../../../../_asset/images/emart/img_layout5.png";
import layout06 from "../../../../_asset/images/emart/img_layout6.png";

const Titlebox = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    margin-bottom: 50px;
  }
`;

const Screen = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
  margin: 100px 0 0 28px;
  font-size: 0;
  line-height: 0;
  text-align: center;
`;

const Textbox = styled.div`
  overflow: hidden;
  margin: 40px 0 0 50px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.5s linear;
  transition: transform 1.5s linear;
`;

const Homescreen = styled.div`
  margin-top: 98px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const Elayout = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 10000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Elayoutcontainer">
      <div className="con_bx">
        <Titlebox trigger={trigger}>
          <img src={Elayoutimg} alt="home_image" />
          <div className="tit_text">
            앱 첫 화면에서 하단으로 스크롤하면 고객에게 <br></br>
            유용한 정보와 혜택들을 한 번에 볼 수 있습니다.
          </div>
        </Titlebox>
        <Screen trigger={trigger}>
          <div className="layout_01">
            <img src={layout01} alt="layout_img01" />
          </div>
          <div className="layout_02">
            <img src={layout02} alt="layout_img02" />
          </div>
        </Screen>
        <Textbox trigger={trigger}>
          <div className="text_title">ONE PAGE CATALOGUE</div>
          <div className="text_sub">
            고객이 이마트앱에 들어와서 머무는 시간은 3분 16초. 장보기 전 잠깐,
            또는 이동 중에도 한 번에 훑어볼 수 있는 <br></br>
            홈을 목표로 설정했습니다. 고객이 매장에서 이마트앱을 이용하더라도
            뎁스 진입 없이 빠르게 정보를 얻을 수 있습니다.
          </div>
        </Textbox>
        <Homescreen trigger={trigger}>
          <ul className="list_image row">
            <li className="layout_list01 col col-4">
              <img src={layout03} alt="layout_img03" />
            </li>
            <li className="layout_list02 col col-4">
              <img src={layout04} alt="layout_img04" />
            </li>
            <li className="layout_list03 col col-4">
              <img src={layout05} alt="layout_img05" />
              <img src={layout06} alt="layout_img06" />
            </li>
          </ul>
        </Homescreen>
      </div>
    </div>
  );
};

export default Elayout;
