import React, { Component } from "react";
import "./Header.scss";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import CloseBtn from "../HamburgerMenu/CloseBtn";

class HeaderPop extends Component {
  render() {
    return (
      <header className="header">
        <div className="header-wrapper">
          <HeaderLogo />
          <CloseBtn onClose={this.props.onClose}></CloseBtn>
        </div>
      </header>
    );
  }
}

export default HeaderPop;
